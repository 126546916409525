import React, { useEffect, useState } from 'react';

import './styles.scss';

import { getTreeData } from '../Services/document.services';
import { getListGroupsByApp } from '../Services/app.services';
import { getDocumentUserList, getUserGroups } from '../Services/user.services';

import DataUtil from '../Utils/DataUtil';
import AppSpinner from './AppSpinner';
import { GlobalProvider } from '../Contexts';
import showNotification from '../Components/Nofitication';
import DocumentApp from './DocumentApp';
import { REFRESH_TOKEN, TOKEN_NAME } from '../../../constants';

export function App() {
    const [treeData, setTreeData] = useState([]);
    const [custodian, setCustodian] = useState([]);
    const [accessGroups, setAccessGroups] = useState([]);
    const [userList, setUserList] = useState([]);
    const [isFetching, setIsFetching] = useState(false);

    const refreshTreeData = async () => {
        try {
            setIsFetching(true);
            const treeData = await getTreeData();
            setTreeData(DataUtil.treeDataConvert(treeData));
        } catch (err) {
            console.log("error");
        } finally {
            setIsFetching(false);
        }
    }

    const fetchingData = async () => {
        try {
            setIsFetching(true);
            const [documentList, custodian, accessGroups, users] = await Promise.all([
                getTreeData(),
                getUserGroups(),
                getListGroupsByApp("NT_Document"),
                getDocumentUserList()
            ]);

            // if (!documentList) {
            //     localStorage.removeItem(TOKEN_NAME);
            //     localStorage.removeItem(REFRESH_TOKEN);
            //     window.location.reload();
            //     return;
            // }

            setTreeData(DataUtil.treeDataConvert(documentList));
            setCustodian(custodian);
            setAccessGroups(accessGroups);
            setUserList(users);
        } catch (err) {
            const notification = {message: "Error", description: `${err.message}`}
            showNotification('error', notification);
        } finally {
            setIsFetching(false);
        }
    }

    useEffect(() => {
        fetchingData();
    }, []);

    return (
        <GlobalProvider
            treeData={treeData}
            custodian={custodian}
            accessGroups={accessGroups}
            userList={userList}
            refreshTreeData={refreshTreeData}
        >
            {isFetching ? (<AppSpinner />) : (<DocumentApp />)}
        </GlobalProvider>
    );
}

export default App;
