import React, { useState } from "react";
import { Button, Form, Row, Col, Checkbox, message } from "antd";
import styled from "styled-components";
import ImgBgLogin from "src/assets/images/bg_login.jpg";
import { useDispatch } from "react-redux";
import PmInput from "src/components/input/PmInput";
import PmInputPassword from "src/components/input/PmInputPassword";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { theme } from "src/constants/color.constant";
import { Radio } from "antd";
import { useTranslation } from "react-i18next";
import { detectLanguage } from "src/utils/utils";
import { confirmForgotPasswordApi } from "../../apis/login";
import { useParams, useHistory } from "react-router-dom";

const LoginWrapper = styled.div`
  background-image: url("${ImgBgLogin}");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  .login-card-container {
    @media (min-width: 1200px) {
      min-height: 80vh;
    }
  }
  .img {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 0;
  }
`;

const CheckboxGreen = styled.div`
  .ant-checkbox-checked::after {
  }
  .ant-checkbox-inner {
    background-color: ${theme.colors.green["300"]};
    width: 19px;
    height: 19px;
  }
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: ${theme.colors.green["300"]};
      border-color: ${theme.colors.green["300"]};
    }
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner {
    border-color: ${theme.colors.green["300"]};
  }
`;

const BtnStyled = styled.div`
  .ant-btn {
    width: 100%;
  }
`;

/**
 * Login Form
 */
const LoginForm = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [langue, setLangue] = useState(detectLanguage());
  // const loginError = useSelector(selectMessageErr);
  const [loginErrors, setLoginErrors] = useState('')
  const [loading, setLoading] = useState(false);
  const { token } = useParams();
  const history = useHistory();
  const onFinish = async (data) => {
    console.log(`data`, data)
    if (data.Password !== data.ConfirmPassword) {
      message.warning("Nhập lại mật khẩu phải trùng với mật khẩu mới")
      return
    }
    data["otp"] = token
    setLoading(true);
    const res = await confirmForgotPasswordApi(data)
    console.log(`res`, res)
    if (res.data.status === 200) {
      message.success("Đổi mật khẩu thành công!")
      setLoginErrors("")
      history.push("/login")
    } else {
      setLoginErrors("Không tìm thấy thông tin tài khoản.")
    }
    setLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  const onChange = (e) => {
    setLangue(e.target.value);
    i18n.changeLanguage(e.target.value).then();
    // if (e.target.value === 'en') {
    //   message.success('Switch Language English Success');
    // } else {
    //   message.success('Switch Language Vietnamese Success');
    // }
  };

  return (
    <LoginWrapper className="tw-h-full tw-px-5">
      <Row className="login-card-row tw-h-full" justify="start" align="middle">
        <Col
          flex={"450px"}
          className="login-card-container tw-flex tw-flex-col tw-justify-between tw-bg-white tw-rounded-3xl tw-pt-4 tw-p-12"
        >
          <div className="login-card-content">
            <div className="tw-text-right">
              <Radio.Group size="middle" onChange={onChange} value={langue}>
                <Radio value="vi" style={{ paddingRight: 20 }}>
                  <img
                    className="img"
                    src="/images/vn1.png"
                    align="top"
                    alt=""
                  />
                </Radio>
                <Radio value="en">
                  <img
                    className="img"
                    src="/images/my.png"
                    align="top"
                    alt=""
                  />
                </Radio>
              </Radio.Group>
            </div>
            <div className="login-card__group tw-pt-1.5">
              <h1 className="tw-font-PlayfairDisplay tw-font-bold tw-text-green-950 tw-text-4xl tw-pt-1.5 tw-text-center tw-mb-11">
                {langue === "vi" ? "Quên mật khẩu" : "Forgot password"}
              </h1>
              <div className={"tw-text-red-600 tw-mb-2"}>{loginErrors}</div>
              <Form
                name="loginForm"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                {/* <Form.Item
                  name="username"
                  rules={[
                    { required: true, message: "Please input your username!" },
                  ]}
                >
                  <PmInput
                    placeholder={"Username"}
                    size="large"
                  />
                </Form.Item>

                <Form.Item
                  name="email"
                  rules={[
                    { required: true, message: "Please input your email!" },
                  ]}
                >
                  <PmInput
                    placeholder={"Email"}
                    size="large"
                  />
                </Form.Item> */}
                <Form.Item
                  name="Password"
                  rules={[
                    { required: true, message: t("fieldRequired") },
                  ]}
                >
                  <PmInputPassword
                    placeholder={t("newPassword")}
                    size="large"
                    iconRender={(visible) =>
                      visible ? (
                        <EyeOutlined
                          style={{ fontSize: 24, color: "#032D23" }}
                        />
                      ) : (
                        <EyeInvisibleOutlined
                          style={{ fontSize: 24, color: "#032D23" }}
                        />
                      )
                    }
                  />
                </Form.Item>
                <Form.Item
                  name="ConfirmPassword"
                  rules={[
                    { required: true, message: t("fieldRequired") },
                  ]}
                >
                  <PmInputPassword
                    placeholder={t("confirmPassword")}
                    size="large"
                    iconRender={(visible) =>
                      visible ? (
                        <EyeOutlined
                          style={{ fontSize: 24, color: "#032D23" }}
                        />
                      ) : (
                        <EyeInvisibleOutlined
                          style={{ fontSize: 24, color: "#032D23" }}
                        />
                      )
                    }
                  />
                </Form.Item>

                <BtnStyled className="btn-login tw-mb-8 tw-mt-8">
                  <Button
                    loading={loading}
                    type="primary"
                    size={"large"}
                    className={
                      "tw-font-Inter tw-font-normal tw-text-white tw-text-xl tw-rounded-md tw-bg-green-950 tw-border-green-950 tw-h-16"
                    }
                    htmlType="submit"
                  >
                    {langue === "vi" ? "Gửi" : "Send"}
                  </Button>
                </BtnStyled>

                <div
                  className={
                    "tw-font-Inter tw-font-normal tw-text-green-950 tw-text-xl"
                  }
                >
                  {/* <span>{t("LOGIN.no_account")}</span> */}
                  <Link to={"/login"} className={"tw-text-green-300"}>
                    {langue === "vi" ? "Quay lại" : "Back"}
                  </Link>
                </div>
              </Form>
            </div>
          </div>
          <div
            className={
              "tw-font-Inner tw-font-normal tw-text-green-950 tw-text-base"
            }
          >
            <p className={"tw-mb-0"}>
              Copyright © 2021 <span>NTS</span> All rights reserved.
            </p>
            <p>Terms & Conditions</p>
          </div>
        </Col>
      </Row>
    </LoginWrapper>
  );
};

export default LoginForm;
