export const configTest = {
  status: 200,
  data: {
    type: "TEST",
    config: [
      {
        sectionName: "generalInformation",
        display: true,
        attribute: {
          testID: {
            default: true,
            display: true,
            type: "textbox",
          },
          testName: {
            default: true,
            display: true,
            type: "textbox",
          },
          associatedSamples: {
            default: true,
            display: true,
            type: "textbox",
          },
          createdBy: {
            default: true,
            display: true,
            type: "textbox",
          },
          associatedInventories: {
            default: true,
            display: true,
            type: "textbox",
          },
          description: {
            default: true,
            display: true,
            type: "textbox",
          },
          sampleIDExternal: {
            default: true,
            display: true,
            type: "textbox",
          },
          completionDate: {
            default: true,
            display: true,
            type: "textbox",
          },
          associatedSamplesExternalID: {
            default: true,
            display: true,
            type: "textbox",
          },
          createdOn: {
            default: true,
            display: true,
            type: "textbox",
          },
          testIDExternal: {
            default: true,
            display: true,
            type: "textbox",
          },
          group: {
            default: true,
            display: true,
            type: "textbox",
          },
          associatedInstruments: {
            default: true,
            display: true,
            type: "textbox",
          },
          code: {
            default: true,
            display: true,
            type: "textbox",
          },
          barcodeID: {
            default: true,
            display: true,
            type: "textbox",
          },
          method: {
            default: true,
            display: true,
            type: "textbox",
          },
          associatedKits: {
            default: true,
            display: true,
            type: "textbox",
          },
          barcodeImage: {
            default: true,
            display: true,
            type: "textbox",
          },
          report: {
            default: true,
            display: true,
            type: "textbox",
          },
          traceLog: {
            default: true,
            display: true,
            type: "link",
          },
          associatedDocument: {
            default: true,
            display: true,
            type: "link",
          },
          type: {
            default: true,
            display: true,
            type: "textbox",
          },
        },
      },
      {
        sectionName: "custodialInformation",
        display: true,
        attribute: {
          technicianID: {
            default: true,
            display: true,
            type: "textbox",
          },
          reviewerID: {
            default: true,
            display: true,
            type: "textbox",
          },
          technicianName: {
            default: false,
            display: false,
            type: "textbox",
          },
          chainOfCustody: {
            default: true,
            display: true,
            type: "textbox",
          },
        },
      },
      {
        sectionName: "turnaroundTimeAlarm",
        display: true,
        attribute: {
          remindBeforeTurnaroundTimeThreshold: {
            default: true,
            display: true,
            type: "textbox",
          },
        },
      },
      {
        sectionName: "testDetails",
        display: true,
        attribute: {
          processingNotes: {
            default: true,
            display: true,
            type: "textbox",
          },
          rejectionCriteria: {
            default: true,
            display: true,
            type: "textbox",
          },
          containerType: {
            default: true,
            display: true,
            type: "dropdown",
            data: ["1", "2"],
          },
          patientPreparation: {
            default: true,
            display: true,
            type: "textbox",
          },
          synonyms: {
            default: true,
            display: true,
            type: "textbox",
          },
          entryDate: {
            default: true,
            display: true,
            type: "date",
          },
          entryTime: {
            default: true,
            display: true,
            type: "date",
          },
        },
      },
    ],
  },
};
