import { Document, PDFViewer, Page, View, Text } from "@react-pdf/renderer";
import React from "react";
import { TitleLeftCustomForPPT, TitleRight } from "../Common/Components/title";
import { monthArr } from "../Common/utils";
import { styles } from "../Common/style";
import { Footer, RenderSigns } from "../Common/Components/footer";
import TextWithCheckBox from "../Common/Components/textWithCheckBox";
import PdfTable from "../Common/Components/pdfTable";

export default function PhieuPhanTich(props) {
  const newData = [];

  props.data.forEach((obj) => {
    const { targetInformation, ...rest } = obj;
    targetInformation.forEach((item) => {
      newData.push({ ...rest, targetInformation: item });
    });
  });
  //Ngang
  const { currentDate, code, getInfo } = props;
  return (
    <PDFViewer style={{ width: "100%", height: "calc(100% - 40px)" }}>
      <Document>
        <Page size="A4" style={[styles.page1Cm]} orientation={"portrait"}>
          <View style={styles.section}>
            <View style={styles.row}>
              <View style={[styles.col_50, styles.textCenter]}>
                <TitleLeftCustomForPPT />
              </View>
              <View style={[styles.col_50, styles.textCenter]}>
                <TitleRight />
              </View>
            </View>

            <View style={{ margin: "30px" }}>
              <Text style={[styles.bold, styles.textCenter, styles.fS16]}>
                PHIẾU PHÂN TÍCH
              </Text>
              <View style={[{ marginLeft: "75%" }]}>
                <Text>
                  Số: {code ? code + "/" + currentDate.getFullYear() : ""}
                </Text>
              </View>
            </View>

            <Information {...props} />
            <View style={{ marginBottom: "5px" }}></View>
            <PdfTable {...props} data={newData} />
            <View style={{ marginBottom: "5px" }}></View>
            <Information2 {...props} />
          </View>
          {/* <Footer
            ngayBanHanh="19/01/2022"
            overrideCode="Ký hiệu: KNYB/BM.7.1.01.03"
            {...props}
          /> */}
        </Page>
      </Document>
    </PDFViewer>
  );
}

function Information(props) {
  const { data, info, getInfo } = props;

  const samplers = [
    ...new Set(
      data
        .flatMap((x) => x.samplerInformation)
        .filter((x) => x?.samplerName)
        .map((x) => x.samplerName)
    ),
  ].join(", ");
  return (
    <View>
      <Text>Tên mẫu phân tích: {getInfo("sampleName")}</Text>
      <Text>Cở sở sản xuất: {getInfo("producerName")}</Text>
      <Text>Cơ sở nhập khẩu: {""}</Text>
      <Text>Số đăng ký/ số GPNK: {getInfo("registerNumber")}</Text>
      <View style={styles.row}>
        <Text style={styles.col_33}>
          Số lô: {getInfo("chronicleBatchDate")}
        </Text>
        <Text style={styles.col_33}>
          Ngày sản xuất: {getInfo("productionDate")}
        </Text>
        <Text style={styles.col_33}>Hạn dùng: {getInfo("expirationDate")}</Text>
      </View>

      <Text>Nơi gửi mẫu: {getInfo("")}</Text>
      <Text>Người gửi mẫu: {getInfo("")}</Text>
      <Text>Yêu cầu phần tích: {getInfo("samplerRequired")}</Text>
      <View style={styles.row}>
        <Text style={styles.col_50}>
          Ngày, tháng, năm nhận mẫu: {getInfo("")}
        </Text>
        <Text style={styles.col_50}>
          Số ĐKPT: {getInfo("registrationNumber")}
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.col_50}>Người giao mẫu: {samplers}</Text>
        <Text style={styles.col_50}>
          Người nhận mẫu: {getInfo("custodianName")}
        </Text>
      </View>

      <Text>Tiêu chuẩn áp dụng: {getInfo("z4")}</Text>
      <Text>
        Tình trạng mẫu khi nhận và khi mở niêm phong để phân tích:{" "}
        {getInfo("status")}
      </Text>
    </View>
  );
}

function Information2(props) {
  const { data, currentSubStanceType, currentDate, getInfo } = props;
  //   const info = data[0] || {};
  let signs = ["THỦ TRƯỞNG ĐƠN VỊ"];

  return (
    <>
      {/* footer signs */}

      <View style={styles.row}>
        <View style={styles.col_50}></View>
        <View style={styles.col_50}>
          <Text style={[styles.textCenter]}>
            Yên Bái, ngày {currentDate.getDate()} tháng{" "}
            {monthArr[currentDate.getMonth()]} năm {currentDate.getFullYear()}
          </Text>
          <RenderSigns titles={signs} bellow={false} />
        </View>
      </View>
    </>
  );
}

function ListCheckBoxes({ list, targets }) {
  const numRow = Math.ceil(list.length / 3);
  const lasted = (index) => index + numRow * 2;

  const isChecked = (input) => {
    return targets.some((item) => item.toLowerCase() === input.toLowerCase());
  };

  return (
    <View>
      {[...Array(numRow)].map((_, index) => (
        <View style={styles.row}>
          <View style={styles.col_33}>
            <TextWithCheckBox
              checked={isChecked(list[index])}
              text={`${index + 1}. ${list[index]}`}
            ></TextWithCheckBox>
          </View>
          <View style={styles.col_33}>
            <TextWithCheckBox
              checked={isChecked(list[index + numRow])}
              text={`${index + numRow + 1}. ${list[index + numRow]}`}
            ></TextWithCheckBox>
          </View>
          {list.length > lasted(index) && (
            <View style={styles.col_33}>
              <TextWithCheckBox
                checked={isChecked(list[lasted(index)])}
                text={`${lasted(index) + 1}. ${list[lasted(index)]}`}
              ></TextWithCheckBox>
            </View>
          )}
        </View>
      ))}
    </View>
  );
}
