import { TOKEN_NAME } from "../constants";
import moment from "moment-timezone";

export const doClearLocalStorage = () => {
  localStorage.removeItem(TOKEN_NAME);
};

export const detectLanguage = () => {
  return localStorage.getItem("i18nextLng") || "vi";
};

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const timeFormatter = (value, officeShift) => {
  if (!value) return "-";

  if (officeShift) {
    return moment.utc(new Date(value)).tz(officeShift).format("MM/DD/YYYY");
  } else {
    const momentDate = moment(new Date(value), moment.ISO_8601);

    return momentDate.isValid() ? momentDate.format("DD/MM/YYYY") : value;
  }
};

export const getTime = (date) => {
  const time = new Date(date);
  return time.getTime();
};

export const handleActionByRole = (actions, userRoles) => {
  if (!userRoles || userRoles.length < 0) return {};
  let newActions = {};
  Object.keys(actions).map((key) => {
    if (actions[key] === true || userRoles.includes(actions[key])) {
      newActions[key] = actions[key];
    }
    return key;
  });
  return newActions;
};

export const handleDownloadFile = (imgUrl) => {
  fetch(imgUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/png",
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      const fileName = imgUrl.split("/").pop();
      link.setAttribute("download", fileName);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    });
};

export const getFormattedDate = (date) => {
  const year = date.getFullYear();
  let month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : "0" + month;
  let day = date.getDate().toString();
  day = day.length > 1 ? day : "0" + day;
  const hh = date.getHours();
  const mm = date.getMinutes();
  const ss = date.getSeconds();
  const timeStr = `${hh < 10 ? "0" + hh : hh}:${mm < 10 ? "0" + mm : mm}:${
    ss < 10 ? "0" + ss : ss
  }`;
  return `${year}-${month}-${day} ${timeStr}`;
};

export const saveByteArray = (fileName, byte) => {
  let binaryString = window.atob(byte);
  let binaryLen = binaryString.length;
  let bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    let ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  let blob = new Blob([bytes], { type: "application/pdf" });
  let link = URL.createObjectURL(blob);
  window.open(link, "_blank");
};

function readGroup(group) {
  let readDigit = [
    " Không",
    " Một",
    " Hai",
    " Ba",
    " Bốn",
    " Năm",
    " Sáu",
    " Bảy",
    " Tám",
    " Chín",
  ];
  var temp = "";
  if (group == "000") return "";
  temp = readDigit[parseInt(group.substring(0, 1))] + " Trăm";
  if (group.substring(1, 2) == "0")
    if (group.substring(2, 3) == "0") return temp;
    else {
      temp += " Lẻ" + readDigit[parseInt(group.substring(2, 3))];
      return temp;
    }
  else temp += readDigit[parseInt(group.substring(1, 2))] + " Mươi";
  if (group.substring(2, 3) == "5") temp += " Lăm";
  else if (group.substring(2, 3) != "0")
    temp += readDigit[parseInt(group.substring(2, 3))];
  return temp;
}

export function readMoney(num) {
  const error = "Không hợp lệ, vui lòng sửa lại đơn vị tiền tệ!";
  if (!num) return error;

  num = num.replaceAll(".", "").replaceAll(",", "");
  if (isNaN(Number.parseFloat(num))) {
    return error;
  }

  let temp = "";
  while (num.length < 18) {
    num = "0" + num;
  }
  let g1 = num.substring(0, 3);
  let g2 = num.substring(3, 6);
  let g3 = num.substring(6, 9);
  let g4 = num.substring(9, 12);
  let g5 = num.substring(12, 15);
  let g6 = num.substring(15, 18);
  if (g1 != "000") {
    temp = readGroup(g1);
    temp += " Triệu";
  }
  if (g2 != "000") {
    temp += readGroup(g2);
    temp += " Nghìn";
  }
  if (g3 != "000") {
    temp += readGroup(g3);
    temp += " Tỷ";
  } else if ("" != temp) {
    temp += " Tỷ";
  }
  if (g4 != "000") {
    temp += readGroup(g4);
    temp += " Triệu";
  }
  if (g5 != "000") {
    temp += readGroup(g5);
    temp += " Nghìn";
  }
  temp = temp + readGroup(g6);
  temp = temp.replaceAll("Một Mươi", "Mười");
  temp = temp.trim();
  temp = temp.replaceAll("Không Trăm", "");
  temp = temp.trim();
  temp = temp.replaceAll("Mười Không", "Mười");
  temp = temp.trim();
  temp = temp.replaceAll("Mươi Không", "Mươi");
  temp = temp.trim();
  if (temp.indexOf("Lẻ") == 0) temp = temp.substring(2);
  temp = temp.trim();
  temp = temp.replaceAll("Mươi Một", "Mươi Mốt");
  temp = temp.trim();
  let result =
    temp.substring(0, 1).toUpperCase() + temp.substring(1).toLowerCase();
  return (result == "" ? "Không" : result) + " đồng chẵn";
}
