import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Form, message, Modal } from "antd";
import { useSelector } from "react-redux";
import { formStatus } from "src/redux/samples/selector";
import { useTranslation } from "react-i18next";
import SectionRender from "./components/sectionRenderr";
import { apiSaveSample } from "../../apis/samples";
import moment from "moment-timezone";
import SamplerInformation from "./SamplerInformation";
import NotifiModal from "./components/NotifiModal";

const initialValueForm = {
  // sampleIdExternal: ''
  currentQuantityUnit: "ml",
  packedSizeUnit: "l",
};
// const imageDefault = [
//   {
//     uid: "-3",
//     name: "image.png",
//     status: "done",
//     url:
//       "https://www.pharmaceutical-technology.com/wp-content/uploads/sites/10/2020/10/Feature-image-top-ten-pharma-companies-in-2020.jpg",
//   },
// ];

const hiddenInputs = ["sampleId"];
export const requireFields = [
  //"custodianName",
  "samplerInformation",
  "sampleName",
  //"dosageForm",
  "currentQuantity",
  "substanceType",
];

const SampleForm = ({
  templateId,
  configData,
  actionRef,
  afterSubmit,
  ...props
}) => {
  const { t } = useTranslation();
  const formRef = useRef();
  const modalSpecifyRef = useRef();
  const modalNotifiRef = useRef();
  const [form] = Form.useForm();
  const [isMounted, setIsMounted] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  // const [editData, setEditData] = useState(null);
  const [hiddenData, setHiddenData] = useState({});
  const { submitting } = useSelector(formStatus);
  const [ishowDrug, setIsShowDrug] = useState(false);
  const [configDt, setConfigDt] = useState([]);
  const [fieldNameSearchUser, setFieldNameSearchUser] = useState(null);
  const [dataSave, setDataSave] = useState({});
  const [id, setId] = useState();
  const [documentId, setDocumentId] = useState(null);

  const [positionSample, setPositionSample] = useState({});
  const [isClearSelectSamplerInforRows, setIsClearSelectSamplerInforRows] =
    useState(false);

  useEffect(() => {
    if (configData?.configData?.config) {
      const data = configData?.configData?.config;
      const pickingInfo = data.find(
        (x) => x.sectionName === "pickingInformation"
      );
      pickingInfo &&
        (pickingInfo.sectionNameLocize = `${pickingInfo.sectionName}_${configData.prefix}`);
      setConfigDt(data);
    }
  }, [configData]);
  useEffect(() => {
    if (!isEdit) {
      const data = configData?.configData?.config;
      setConfigDt(data);
    }
  }, [isEdit]);
  const handleOk = () => {
    if (formRef.current) {
      formRef.current.submit();
    }
  };
  const handleCancel = () => {
    handleClose();
  };
  const handleClose = () => {
    setIsEdit(false);
    // setEditData(null);
    setIsClearSelectSamplerInforRows(!isClearSelectSamplerInforRows);
    setVisible(false);
    form.resetFields();
  };
  const handleOpen = (config, record) => {
    const tableRecord = record ? JSON.parse(JSON.stringify(record)) : undefined;
    const configTemp = JSON.parse(JSON.stringify(config.configData.config));

    const indexSubInfo = config.configData.config.findIndex(
      (item) => item.sectionName === "substanceInformation"
    );

    const indexTargetInfo = config.configData.config.findIndex(
      (item) => item.sectionName === "targetInformation"
    );

    if (indexSubInfo > -1 || indexTargetInfo > -1) {
      [indexSubInfo, indexTargetInfo]
        .filter((x) => x > -1)
        .forEach((index) => {
          //convert attr from obj to array

          const newAttr = [];
          let number =
            tableRecord && tableRecord[configTemp[index].sectionName]
              ? tableRecord[configTemp[index].sectionName].length
              : 1;
          if (number < 1) {
            number = 1;
          }
          for (let i = 0; i < number; i++) {
            newAttr.push({
              ...configTemp[index].attribute,
              action: {
                default: true,
                display: true,
                type: "action",
              },
            });
          }

          configTemp[index].attribute = newAttr;
        });
    }

    setIsShowDrug(tableRecord ? tableRecord["importDrug"] : false);

    if (tableRecord) {
      setDocumentId(tableRecord["documentId"]);
      setId(tableRecord["sampleId"]);
      Object.keys(tableRecord).map((key) => {
        if (key === "position") {
          const array = (tableRecord[key] || "").split(",");
          if (array.length === 2) {
            setPositionSample({
              latitude: parseFloat(array[0]),
              longitude: parseFloat(array[1]),
            });
          }
        }
        if (hiddenInputs.includes(key)) {
          setHiddenData((prev) => ({
            ...prev,
            [key]: tableRecord[key],
          }));
        }
        for (let item of config.configData.config) {
          for (const [key, value] of Object.entries(item.attribute)) {
            if (value.type === "date") {
              tableRecord[key] = tableRecord[key]
                ? moment(tableRecord[key])
                : undefined;
            }
          }
        }
        return key;
      });

      if (
        Array.isArray(tableRecord.samplerInformation) &&
        tableRecord.samplerInformation.length > 0
      ) {
        let datas = [];
        let configSampler = {};
        tableRecord.samplerInformation.map((item, index) => {
          Object.keys(item).map((items, idx) => {
            configSampler[`${items}`] = {
              default: true,
              display: true,
              type: "textbox",
            };
          });
          datas.push(configSampler);
        });
        let item = configTemp.findIndex(
          (item) => item.sectionName === "samplerInformation"
        );
        configTemp[item] = {
          display: true,
          sectionName: "samplerInformation",
          attribute:
            datas.length > 0
              ? datas
              : [
                  {
                    samplerName: {
                      default: true,
                      display: true,
                      type: "textbox",
                    },
                    samplerId: {
                      default: true,
                      display: true,
                      type: "textbox",
                    },
                    samplerPosition: {
                      default: true,
                      display: true,
                      type: "textbox",
                    },
                    samplerIdCard: {
                      default: true,
                      display: true,
                      type: "textbox",
                    },
                    samplerDepartment: {
                      default: true,
                      display: true,
                      type: "textbox",
                    },
                  },
                ],
        };
      }
    }

    setConfigDt(configTemp);
    //set Form values
    if (tableRecord) {
      form.setFieldsValue(tableRecord);
      setIsEdit(true);
    }

    setVisible(true);
  };

  const onUpload = (val) => {
    const { fieldName, path } = val;
    form.setFieldsValue({
      [fieldName]: path,
    });
  };

  const onFinish = async (data) => {
    /*
      Validate
    */
    const {
      fromDate,
      issuedDate,
      planningDate,
      toDate,
      custodianName,
      samplerInformation,
      sampleName,
      dosageForm,
      registerNumber,
      currentQuantity,
      unit,
      productionDate,
      expirationDate,
      receivedDate,
      chronicleBatchDate,
    } = data;

    for (let i in data) {
      //hard code substanceType
      if (
        i === "substanceInformation" &&
        requireFields.includes("substanceType") &&
        Array.isArray(data[i])
      ) {
        if (
          data[i].length !==
          data[i].map((x) => x.substanceType).filter((x) => x).length
        ) {
          message.error(
            `${t("SAMPLE.FORM.substanceType")} là trường bắt buộc!`
          );
          return;
        }
      }

      if (requireFields.includes(i) && !data[i]) {
        message.error(`${t(`SAMPLE.FORM.${i}`)} là trường bắt buộc!`);
        return;
      }
    }

    if (issuedDate && planningDate && issuedDate < planningDate) {
      message.error("`Ngày lập kế hoạch` phải trước `Ngày ban hành`");
      return;
    }
    if (fromDate && toDate && toDate < fromDate) {
      message.error("Trường `Đến ngày` phải trước trường `Từ ngày`");
      return;
    }

    if (!isEdit) {
      setDataSave(data);
      modalNotifiRef.current.openNotifiModal();
    } else {
      const formData = convertConfigDtToFormData(data);
      if (!id) {
        message.error(
          "Lỗi thiếu sampleId! Vui lòng liên hệ admin để biết thêm chi tiết."
        );
      }
      await saveSample(formData, null, id);
    }
  };

  function convertConfigDtToFormData(data) {
    let result = {};

    configDt.map((item) => {
      if (Array.isArray(item.attribute)) {
        if (item.sectionName === "samplerInformation") {
          result[item.sectionName] = form.getFieldValue("samplerInformation");
        }
        if (item.sectionName === "substanceInformation") {
          result[item.sectionName] = form.getFieldValue("substanceInformation");
        }
        if (item.sectionName === "targetInformation") {
          result[item.sectionName] = form.getFieldValue("targetInformation");
        }
      } else {
        const child = {};

        Object.keys(item.attribute).map((key) => {
          if (hiddenData[key]) {
            return (child[key] = hiddenData[key]);
          }
          if (item.attribute[key].type.toUpperCase() === "number") {
            return (child[key] = parseInt(data[key]));
          }
          if (data[key]) {
            return (child[key] = data[key]);
          }
          if (item.sectionName !== "samplerInformation") child[key] = null;

          return key;
        });

        if (Object.keys(child).length > 0) {
          result[item.sectionName] = child;
        }
      }

      result.position = positionSample.latitude
        ? `${positionSample.latitude},${positionSample.longitude}`
        : "";
      return result;
    });

    if (result["generalInformation"]) {
      result["generalInformation"]["importDrug"] = ishowDrug || false;
    }

    if (documentId && result["generalInformation"]) {
      result["generalInformation"]["documentId"] = documentId;
    }
    return result;
  }

  async function saveSample(formData, successCallback, sampleId = null) {
    const isEdit = !!sampleId;

    const res = await apiSaveSample(formData, templateId, sampleId);
    if (res.status === 200 || res.status === 201) {
      message.success(
        t(`SAMPLE.MESSAGE.${isEdit ? "editSampleSuccess" : "addSampleSuccess"}`)
      );

      if (successCallback) {
        successCallback();
      } else {
        handleClose();
        afterSubmit && afterSubmit();
      }
    } else {
      message.error(
        res?.data?.message ||
          t(`SAMPLE.MESSAGE.${isEdit ? "editSampleFailed" : "addSampleFailed"}`)
      );
    }
  }

  const setIsSaveContinues = async (isCheckContinue) => {
    const formData = convertConfigDtToFormData(dataSave);
    let successCallback;
    if (isCheckContinue) {
      successCallback = function () {
        let resetData = {};
        let val = Object.keys(formData);
        for (let item of val) {
          if (
            item === "substanceInformation" ||
            item === "generalInformation" ||
            item === "quantityInformation" ||
            item === "producerInformation" ||
            item === "chronicleInformation" ||
            item === "custodianInformation"
          ) {
            if (formData[item])
              for (let ite of Object.keys(formData[item])) {
                resetData[ite] = undefined;
              }
          }

          if (item === "substanceInformation") {
            if (Array.isArray(formData[item])) {
              let temp = [];
              for (let index in formData[item])
                if (formData[item][index]) temp.push({});
              resetData[item] = temp;
            }
          }
        }
        let values = form.getFieldsValue();
        form.setFieldsValue({ ...values, ...resetData });
      };
    }

    await saveSample(formData, successCallback);
  };

  useEffect(() => {
    if (!isMounted) return;
    if (!submitting) {
      setVisible(false);
      form.resetFields();
      if (afterSubmit) {
        afterSubmit();
      }
    }
    // eslint-disable-next-line
  }, [submitting]);

  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true);
    }
    const userAction = {
      close: handleClose,
      open: handleOpen,
    };
    if (actionRef && typeof actionRef === "function") {
      actionRef(userAction);
    }
    if (actionRef && typeof actionRef !== "function") {
      actionRef.current = userAction;
    }
    return () => {
      setIsMounted(false);
    };
    // eslint-disable-next-line
  }, []);
  const changeValue = (value, all) => {
    if (Object.keys(value)[0] == "importDrug") {
      setIsShowDrug(value.importDrug);
    }
  };
  const onSearch = () => {
    setFieldNameSearchUser("samplerName");
    modalSpecifyRef.current.openSamplerModal();
  };
  const onSearchCustodianName = () => {
    setFieldNameSearchUser("custodianName");
    modalSpecifyRef.current.openSamplerModal();
  };

  const onSelect = (data = []) => {
    if (data.length === 0) return;
    if (fieldNameSearchUser === "samplerName") {
      let dataConvert = [];
      for (let item of data) {
        dataConvert.push({
          samplerName: item.Username,
          samplerId: item.MaUser,
          samplerPosition: item.Position,
          samplerIdCard: item.CMND,
          samplerDepartment: item.Position,
        });
      }
      let configSampler = {};
      let datas = [];
      if (dataConvert.length > 0) {
        dataConvert.map((item, index) => {
          Object.keys(item).map((items, idx) => {
            configSampler[`${items}`] = {
              default: true,
              display: true,
              type: "textbox",
            };
          });
          datas.push(configSampler);
        });
        let configTemp = [...configDt];
        let item = configTemp.findIndex(
          (item) => item.sectionName === "samplerInformation"
        );
        configTemp[item] = {
          display: true,
          sectionName: "samplerInformation",
          attribute:
            datas.length > 0
              ? datas
              : [
                  {
                    samplerName: {
                      default: true,
                      display: true,
                      type: "textbox",
                    },
                    samplerId: {
                      default: true,
                      display: true,
                      type: "textbox",
                    },
                    samplerPosition: {
                      default: true,
                      display: true,
                      type: "textbox",
                    },
                    samplerIdCard: {
                      default: true,
                      display: true,
                      type: "textbox",
                    },
                    samplerDepartment: {
                      default: true,
                      display: true,
                      type: "textbox",
                    },
                  },
                ],
        };
        let value = form.getFieldsValue();

        form.setFieldsValue({
          ...value,
          samplerInformation: data,
        });
        setConfigDt(configTemp);
      }
    }

    if (fieldNameSearchUser === "custodianName") {
      let configTemp = [...configDt];
      let item = configTemp.findIndex(
        (item) => item.sectionName === "custodianInformation"
      );
      configTemp[item].attribute.custodianUserId = {
        default: true,
        display: true,
        type: "textbox",
      };
      let value = form.getFieldsValue();
      form.setFieldsValue({
        ...value,
        custodianUserId: data[0].samplerUserId,
        custodianPosition: data[0].samplerPosition,
        custodianId: data[0].samplerId,
        custodianName: data[0].samplerName,
        custodian: data[0].samplerDepartment,
      });
      setConfigDt(configTemp);
    }
  };

  const setConfigFirst = (sectionName = "substanceInformation") => {
    const configTemp = JSON.parse(JSON.stringify(configDt));

    const indexBySectionName = configTemp.findIndex(
      (item) => item.sectionName === sectionName
    );

    if (indexBySectionName > -1) {
      const attribute = configTemp[indexBySectionName].attribute;
      attribute.push(attribute[0]);

      setConfigDt(configTemp);
    }
  };

  const deleteConfig = (index, sectionName = "substanceInformation") => {
    form.setFieldsValue({
      [sectionName]: form
        .getFieldValue(sectionName)
        .filter((x, i) => i !== index),
    });

    let indexBySectionName = configDt.findIndex(
      (item) => item.sectionName === sectionName
    );

    setConfigDt((prevConfigDt) => {
      const updatedConfigDt = [...prevConfigDt];

      const item = updatedConfigDt[indexBySectionName];
      const updatedAttribute = [...item.attribute];
      updatedAttribute.splice(index, 1);

      item.attribute = updatedAttribute;

      return updatedConfigDt;
    });
  };

  const handleChangeForm = (key, v) => {
    let value = form.getFieldsValue();
    if (key === "documentId") setDocumentId(v);
    form.setFieldsValue({
      ...value,
      [key]: v,
    });
  };

  return (
    <div>
      <Modal
        centered={true}
        title={
          isEdit ? t("DASHBOARD.edit_sample") : t("DASHBOARD.add_new_sample")
        }
        visible={visible}
        onOk={handleOk}
        confirmLoading={submitting}
        onCancel={handleCancel}
        cancelButtonProps={{ style: { display: "none" } }}
        okText={t("BUTTON.save")}
        okButtonProps={{ htmlType: "submit" }}
        width={1000}
        forceRender
        maskClosable={false}
      >
        <Form
          ref={formRef}
          form={form}
          name="basic"
          labelCol={{ span: 11 }}
          wrapperCol={{ span: 13 }}
          initialValues={initialValueForm}
          onFinish={onFinish}
          autoComplete="off"
          onValuesChange={(value, allvalue) => changeValue(value, allvalue)}
        >
          {configDt?.map((item, idx) => (
            <SectionRender
              key={idx}
              item={item}
              ishowDrug={ishowDrug}
              hiddenInputs={hiddenInputs}
              onUpload={onUpload}
              onSearch={onSearch}
              onSearchCustodianName={onSearchCustodianName}
              positionSample={positionSample}
              setPositionSample={setPositionSample}
              setConfigFirst={setConfigFirst}
              deleteConfig={deleteConfig}
              handleChangeForm={handleChangeForm}
            />
          ))}
        </Form>
      </Modal>
      <SamplerInformation
        fieldNameSearchUser={fieldNameSearchUser}
        actionRef={modalSpecifyRef}
        onSelectItem={onSelect}
        isClearSelectSamplerInforRows={isClearSelectSamplerInforRows}
      />
      <NotifiModal
        actionRef={modalNotifiRef}
        setIsSaveContinue={setIsSaveContinues}
      />
    </div>
  );
};

SampleForm.propTypes = {
  actionRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  afterSubmit: PropTypes.func,
};

export default SampleForm;
