import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Input, Select, message, Popconfirm, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { CloseCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import {
  saveSampleConfig,
  getSampleConfigByKey,
  deleteSampleConfig,
} from "../../../apis/saveConfig";

const { TextArea } = Input;

export default function DropdownlistCRUD(props) {
  const { span, value, field, onChange } = props;
  const { t } = useTranslation();
  const inputRef = useRef();

  const [isCreate, setIsCreate] = useState(false);
  const [crudValue, setCrudValue] = useState("");
  const [dataSource, setDataSource] = useState([]);

  const fetchDataSource = async () => {
    const { data } = await getSampleConfigByKey(field);
    const values = data?.Values;
    if (values) {
      setDataSource(values);
    }
  };

  useEffect(() => {
    fetchDataSource();
  }, []);

  useEffect(() => {
    if (isCreate) {
      setCrudValue("");
      inputRef.current && inputRef.current.focus();
    }
  }, [isCreate]);

  const JS_SUBMIT_CLASSNAME = "js-quick-add-submit";
  const ICON_STYLE = {
    cursor: "pointer",
    paddingTop: "0.5rem",
    margin: "0 5px 0 5px",
    fontSize: "16px",
    color: "#1890ff",
  };

  const validateSubmitValue = (value) => {
    let result = true;
    if (!value.trim()) {
      message.error(`${t(`SAMPLE.FORM.${field}`)} không được để trống!`);
      result = false;
    }
    //  else if (REGEX.regexSpecialChar.test(value)) {
    //   message.error(
    //     `${t(`SAMPLE.FORM.${field}`)} ${REGEX.regexSpecialCharMessage}`
    //   );
    //   result = false;
    // }
    else if (
      dataSource.some((item) => item.toLowerCase() === value.toLowerCase())
    ) {
      //check duplicate name
      message.error(
        `${t(`SAMPLE.FORM.${field}`)}: giá trị ${value} đã tồn tại!`
      );
      result = false;
    }
    return result;
  };

  const handleDelete = async (deleteValue) => {
    const response = await deleteSampleConfig({
      value: deleteValue,
      key: field,
    });

    if (response.status === 200) {
      setDataSource(response.data.Values);
      message.success(`Xóa thành công!`);
      onChange && onChange();
    } else {
      message.error(`Lỗi bất định!`);
    }
  };

  const onSubmit = async () => {
    const saveValue = crudValue.trim();
    if (validateSubmitValue(saveValue)) {
      const response = await saveSampleConfig({
        value: saveValue,
        key: field,
      });

      if (response.status === 200) {
        setIsCreate(false);
        setDataSource(response.data.Values);
        message.success(`Thêm thành công!`);
      } else {
        message.error(`Lỗi bất định!`);
      }
    }
  };

  return (
    <Col span={span}>
      {!isCreate ? (
        <>
          <Select
            showSearch
            value={value}
            onChange={(e) => onChange && onChange(e)}
          >
            {dataSource.map((item) => (
              <Select.Option
                style={{ position: "relative" }}
                key={item}
                value={item}
              >
                <Tooltip placement="rightTop" title={item}>
                  {item}
                </Tooltip>
                {/* SSOI nút xóa trong ddl */}
                {/* <p
                    style={{
                      position: "absolute",
                      top: 5,
                      right: 5,
                      cursor: "pointer",
                      padding: "0 10px 0 10px",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDelete(d.id);
                    }}
                  >
                    <DeleteOutlined />
                  </p> */}
              </Select.Option>
            ))}
          </Select>
          <div className="tw-absolute">
            <PlusCircleOutlined
              style={{
                ...ICON_STYLE,
                color: "#1890ff",
              }}
              onClick={() => {
                setIsCreate(true);
              }}
            />
            {value && (
              <Popconfirm
                placement="bottomLeft"
                title="Bạn có chắc chắn muốn xóa giá trị đã chọn không?"
                onConfirm={() => {
                  handleDelete && handleDelete(value);
                }}
                okText={t("BUTTON.yes")}
                cancelText={t("BUTTON.no")}
              >
                <CloseCircleOutlined
                  style={{
                    ...ICON_STYLE,
                    color: "red",
                  }}
                />
              </Popconfirm>
            )}
          </div>
        </>
      ) : (
        <>
          <TextArea
            ref={inputRef}
            value={crudValue}
            onChange={(e) => setCrudValue(e.target.value)}
            onBlur={(e) => {
              if (!e?.relatedTarget?.classList?.contains(JS_SUBMIT_CLASSNAME)) {
                setIsCreate(false);
              } else {
                inputRef.current && inputRef.current.focus();
              }
            }}
          />
          <Button
            type="primary"
            onClick={onSubmit}
            className={`tw-w-full123 ${JS_SUBMIT_CLASSNAME}`}
          >
            Lưu
          </Button>
        </>
      )}
    </Col>
  );
}
