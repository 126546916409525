import React, { useEffect, useRef, useState } from "react";
import Datatable from "../../components/Datatable";
import { useTranslation } from "react-i18next";
import TestForm from "./testForm";
import ResultEntriesForm from "./ResultEntries";
import { Button, Input, message, Modal, Select } from "antd";
import { getListTestCode, getListTestGroup } from "../../apis/configTest";
import ModalValidation from "./ModalValidation";
import ModalValidation2 from "./ModalValidation2";
import ModalReturnResults from "./ModalReturnResults";
import { apiAddTestInformationStatus, apiDeleteTestInformation, getListTestInformation } from "../../apis/testInformation";
import { PDFViewer } from "@react-pdf/renderer";
import TestPdfView from "../../components/SamplePdf/TestPdfView";
import { ExportOutlined } from "@ant-design/icons";
import { timeFormatter } from "../../utils/utils";
import { Link } from "react-router-dom";

const PARAMS = {
  page: 1,
  size: 999,
}

const Test = (props) => {
  const { t } = useTranslation();
  const columns = [
    {
      title: "ID",
      width: 200,
      dataIndex: "testInformationCode",
    },
    {
      title: "Mã thử nghiệm",
      width: 200,
      dataIndex: "testIdResponseDTO.testId",
    },
    {
      title: t("TEST.FORM.testName"),
      width: 200,
      dataIndex: "testName",
    },
    // {
    //   title: t("TEST.FORM.technicianID"),
    //   width: 200,
    //   dataIndex: "technicianInformationDTO.technicianId",
    // },
    {
      title: t("TEST.FORM.type"),
      width: 200,
      dataIndex: "testIdResponseDTO.type",
      render: (x) => <div>{t(x)}</div>
    },
    // {
    //   title: t("TEST.FORM.sampleIDExternal"),
    //   width: 200,
    //   dataIndex: "generalInformation.associatedInventories",
    // },
    // {
    //   title: t("TEST.FORM.sampleTestingStatus"),
    //   width: 200,
    //   dataIndex: "generalInformation.description",
    // },
    // {
    //   title: t("TEST.FORM.sampleTestRuns"),
    //   width: 200,
    //   dataIndex: "generalInformation.sampleIDExternal",
    // },
    // {
    //   title: t("TEST.FORM.testCategory"),
    //   width: 200,
    //   dataIndex: "generalInformation.completionDate",
    // },
    // {
    //   title: t("TEST.FORM.chainOfCustody"),
    //   width: 200,
    //   dataIndex: "generalInformation.createdOn",
    // },
    // {
    //   title: t("TEST.FORM.testCategory"),
    //   width: 200,
    //   dataIndex: "generalInformation.testIDExternal",
    // },
    // {
    //   title: t("TEST.FORM.testChronology"),
    //   width: 200,
    //   dataIndex: "generalInformation.group",
    // },
    // {
    //   title: t("TEST.FORM.nextTest"),
    //   width: 200,
    //   dataIndex: "generalInformation.previousTest",
    // },
    // {
    //   title: t("TEST.FORM.associatedInventories"),
    //   width: 200,
    //   dataIndex: "generalInformation.code",
    // },
    // {
    //   title: t("TEST.FORM.associatedRequestId"),
    //   width: 200,
    //   dataIndex: "generalInformation.barcodeID",
    // },
    {
      title: t("TEST.FORM.sampleIDExternal"),
      width: 200,
      dataIndex: "generalInformation.sampleIdExternal",
    },
    {
      title: t("TEST.FORM.sampleIdInternal"),
      width: 200,
      dataIndex: "generalInformation.sampleIdInternal",
    },
    {
      title: "Tên mẫu",
      width: 200,
      dataIndex: "generalInformation.sampleName",
    },
    {
      title: "Chi tiết mẫu",
      width: 200,
      dataIndex: "generalInformation.sampleId",
      render: (id) => <Link to={"/sample/" + id}>Link</Link>
    },
    // {
    //   title: t("TEST.FORM.turnaroundTimeStatus"),
    //   width: 200,
    //   dataIndex: "custodialInformation.technicianID",
    // },
    // {
    //   title: t("TEST.FORM.testResultsValidationStatus"),
    //   width: 200,
    //   dataIndex: "custodialInformation.reviewerID",
    // },
    // {
    //   title: t("TEST.FORM.validationRemarks"),
    //   width: 200,
    //   dataIndex: "custodialInformation.technicianName",
    // },
    {
      title: t("TEST.FORM.qcSample"),
      width: 200,
      dataIndex: "testingResultDTO.qcSample",
      render: (x) => <div>{x ? "Có" : ""}</div>
    },
    // {
    //   title: t("TEST.FORM.associatedInstruments"),
    //   width: 200,
    //   dataIndex: "testDetails.processingNotes",
    // },
    {
      title: t("TEST.FORM.sampleTestingStatus"),
      width: 200,
      dataIndex: "generalInformation.sampleStatus",
      render: (x) => x ? t(`STATUSTEMPLATESAMPLE.${x}`) : ""
    },
    // {
    //   title: t("TEST.FORM.barcodeID"),
    //   width: 200,
    //   dataIndex: "testDetails.containerType",
    // },
    {
      title: t("TEST.FORM.technicianName"),
      width: 200,
      dataIndex: "technicianInformationDTO.technicianName",
    },
    {
      title: t("TEST.FORM.reviewer"),
      width: 200,
      dataIndex: "technicianInformationDTO.reviewer",
    },
    {
      title: t("TEST.FORM.approver"),
      width: 200,
      dataIndex: "technicianInformationDTO.approver",
    },
    {
      title: t("TEST.FORM.result"),
      width: 200,
      dataIndex: "sectionName.result",
    },
    {
      title: t("TEST.FORM.otherLabResults"),
      width: 200,
      dataIndex: "sectionName.otherLabResults",
    },
    {
      title: t("TEST.FORM.remark"),
      width: 200,
      dataIndex: "testingResultDTO.remarks",
    },
    // {
    //   title: t("TEST.FORM.resultedBy"),
    //   width: 200,
    //   dataIndex: "sectionName.resultedBy",
    // },
    {
      title: t("TEST.FORM.validDate"),
      width: 200,
      dataIndex: "sectionName.validDate",
      render: (value, x) => {
        return timeFormatter(value || x?.["sectionName.resultDate"]);
      },
    },
    // {
    //   title: t("TEST.FORM.reportGenerationStatus"),
    //   width: 200,
    //   dataIndex: "testDetails.synonyms",
    // },
    {
      title: t("TEST.FORM.status"),
      width: 200,
      fixed: "right",
      dataIndex: "status",
      render: (status) => <div> {status ? t(`STATUSTEMPLATETEST.${status}`) : ""}</div>
    },
  ];

  const modalRef = useRef();
  const modalRefEntries = useRef();
  const tableRef = useRef();
  const modalValidationRef = useRef();
  const modalValidationRef2 = useRef();
  const modalValidationRef3 = useRef();
  // const { data, loading } = useSelector(selectTests);
  const [form, setForm] = useState({});
  const [dataForms, setDataForms] = useState([]);
  const [currentTemplate, setcurrentTemplate] = useState(null);
  const [testCode, setTestCode] = useState("");
  const [search, setSearch] = useState("")
  const [data, setData] = useState([])
  const [dataTestCode, setDataTestCode] = useState([])
  const [loading, setLoading] = useState(false);
  const [visibleValidation, setVisibleValidation] = useState(false);
  const [dataSelected, setDataSelected] = useState([]);
  const [visiblePdf, setVisiblePdf] = useState(false);

  const fetchListTemplate = async () => {
    const res = await getListTestGroup("TEST");
    if (res.status === 200) {
      setDataForms(res.data || []);
      setForm(res.data[0] || {});
      setcurrentTemplate(res.data[0]?.id || "");
      // setDataTestCode((res.data[0]?.testIdResponseDTOS || []).map(x => { return { ...x, name: x?.testCodeDTO?.name } }))
    }
  };

  const fetchTestCode = async () => {
    if (!currentTemplate) return
    const { data } = await getListTestCode(currentTemplate)
    let d = Array.isArray(data) ? data : []
    d.unshift({ id: "0", name: "Tất cả" })
    setDataTestCode(d);
    setTestCode(data?.[0]?.id)
  }

  useEffect(() => {
    fetchTestCode()
  }, [currentTemplate])

  useEffect(() => {
    // getList();
    fetchListTemplate()
  }, [])

  useEffect(() => {
    if (!currentTemplate) return;
    getList();
    setDataSelected([]);
  }, [currentTemplate, testCode])

  const getList = async () => {
    setLoading(true);
    const { data } = await getListTestInformation({ ...PARAMS, testGroupId: currentTemplate, testCodeId: testCode == "0" ? null : testCode });
    // const { data } = await getListTestInformation({ ...PARAMS, testGroupId: currentTemplate, testCodeId: testCode });
    setData(data?.content || []);
    setLoading(false);
  }
  const handleDelete = async (ids) => {
    const res = await apiDeleteTestInformation({ objectList: ids });
    await getList();
  };

  const openForm = (record) => {
    if (testCode == "0") {
      message.warning("Vui lòng chọn test code!")
      return;
    }
    if (record && (record.status === "APPROVED" || record.status === "LOCKED")) {
      message.warning("Trạng thái hiện tại không thể sửa!")
      return;
    }
    if (modalRef.current) {
      modalRef.current.openTestForm(currentTemplate, testCode);
      if (record) {
        modalRef.current.setFieldsValue(record, currentTemplate, testCode);
      }
    }
  };
  const openFormResultEntries = () => {
    if (modalRefEntries.current) {
      modalRefEntries.current.openResultEntries();
    }
  };
  const clearSelectRows = () => {
    if (tableRef.current) {
      tableRef.current.clearSelectRows();
    }
    reset();
  };

  const changeForm = (id) => {
    let val = dataForms.find((item) => item.id === id);
    setForm(val);
    setcurrentTemplate(val.id);
  };

  const changeTestCode = (id) => {
    setTestCode(id);
  };

  const onExport = () => {
    if (!dataSelected || dataSelected.length !== 1) {
      return message.warning("Vui lòng chọn 1 bản ghi!");
    } else {
      if (false) {
        let listSamplerCheck = dataSelected[0].samplerInformation.map(
          (i) => i.samplerUserId
        );
        let localCheck = dataSelected[0].locationName;
        for (let i = 0; i < dataSelected.length; i++) {
          let listSampler = dataSelected[i].samplerInformation.map(
            (i) => i.samplerUserId
          );
          if (dataSelected[i].locationName !== localCheck) {
            return message.warning(t("SAMPLE.MESSAGE.different_location_name"));
          } else if (listSamplerCheck.length !== listSampler.length) {
            return message.warning(t("SAMPLE.MESSAGE.different_sampler"));
          } else {
            for (let item of listSamplerCheck) {
              if (
                (listSamplerCheck || listSampler) &&
                !listSampler.includes(item)
              ) {
                return message.warning(t("SAMPLE.MESSAGE.different_sampler"));
              }
            }
          }
        }
        setVisiblePdf(true);
        // for(let item of dataSelected){
        //   if(item.custodianName !== localCheck){

        //   }
        // }
      } else {
        setVisiblePdf(true);
      }
    }
  };

  const toolbarExtra = () => {
    return (
      <div style={{ display: "flex" }}>
        <div style={{ marginRight: 20 }}>
          <Select
            showSearch
            style={{ width: "100px !important", marginRight: 20 }}
            placeholder="Select a template"
            optionFilterProp="children"
            onChange={(value) => changeForm(value)}
            value={currentTemplate || null}
            // value={module}
            className="custom-ant-select"
          >
            {dataForms
              ? dataForms.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))
              : ""}
          </Select>
        </div>
        <div style={{ marginRight: 20 }}>
          <Select
            showSearch
            style={{ width: "100px !important", marginRight: 20 }}
            placeholder="Select a template"
            optionFilterProp="children"
            onChange={(value) => changeTestCode(value)}
            value={testCode || null}
            // value={module}
            className="custom-ant-select"
          >
            {(dataTestCode || []).map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))
            }
          </Select>
        </div>
        <div><Input.Search allowClear value={search} onChange={e => setSearch(e.target.value)} /></div>
        {true && (
          <Button type="primary" size={"default"} onClick={onExport}>
            <ExportOutlined /> {t("ACTIONS.export")}
          </Button>
        )}
      </div>
    );
  };

  const onShowValidation = (r) => {
    console.log('r', r, dataSelected);
    if (r.length !== 1) {
      message.warning("Vui lòng chọn 1 bản ghi!")
      return
    }
    // if (dataSelected[0].status !== "HAS_REVIEWED" && dataSelected[0].status !== "TEST_DONE") {
    //   message.warning(`Trạng thái ${t("STATUSTEMPLATETEST.HAS_REVIEWED")} hoặc ${t("STATUSTEMPLATETEST.TEST_DONE")} mới được sử dụng thao tác này!`)
    //   return
    // }
    if (modalValidationRef.current)
      modalValidationRef.current.open(r);
  }

  const onShowValidation2 = (r) => {
    if (r.length !== 1) {
      message.warning("Vui lòng chọn 1 bản ghi!")
      return
    }
    if (dataSelected[0].status !== "REQUEST_APPROVED") {
      message.warning(`Trạng thái ${t("STATUSTEMPLATETEST.REQUEST_APPROVED")} mới được sử dụng thao tác này!`)
      return
    }
    if (modalValidationRef2.current)
      modalValidationRef2.current.open(r);
  }

  const onShowValidation3 = () => {
    if (dataSelected.length !== 1) {
      message.warning("Vui lòng chọn 1 bản ghi!")
      return
    }
    if (dataSelected[0].status === "LOCKED") {
      message.warning("Trạng thái hiện tại không thể trả kết quả!")
      return
    }
    if (modalValidationRef3.current)
      modalValidationRef3.current.open(dataSelected);
  }

  const reset = async () => {
    await getList();
  }

  const onChangeStatusTest = async (selected, testStatus, unlock) => {
    if (testStatus === "LOCKED" && unlock === false) {
      let r = false
      dataSelected.every(d => {
        if (d.status !== "LOCKED") {
          message.warning("Bản ghi phải ở trạng thái Khóa")
          r = true;
          return;
        }
      })
      if (r === true) {
        return
      }
    }
    const { data, status } = await apiAddTestInformationStatus({
      objectList: selected,
      testStatus,
      unlock: !unlock,
    })
    if (status === 200) {
      message.success("Thay đổi trạng thái thành công!")
    }
    else {
      message.error(data.message || "Thay đổi trạng thái không thành công!")
    }
    reset();
  }

  return (
    <div>
      <Datatable
        tableRef={tableRef}
        loading={loading}
        columns={columns}
        dataSource={data}
        rowKey={"id"}
        autoFlatData={true}
        allowSelect={true}
        onDelete={handleDelete}
        onAddNew={openForm}
        onEdit={(record) => openForm(record)}
        onResultEntries={openFormResultEntries}
        onShowValidation={onShowValidation}
        onShowValidation2={onShowValidation2}
        onShowReturnResult={onShowValidation3}
        toolbarExtra={toolbarExtra()}
        onChangeStatusTest={onChangeStatusTest}
        onSelect={(records) => setDataSelected(records)}
        inputSearch={search}
        actionConfig={{
          allowEdit: true,
          allowAddNew: true,
          allowDelete: true,
          // resultEntries: true,
          allowTest1: true,
          allowTest2: true,
          allowTest3: true,
          allowTest4: true,
          allowTest5: true,
          allowTest6: true,
        }}
      />

      <TestForm
        configData={form}
        actionRef={modalRef}
        afterSubmit={clearSelectRows}
      />
      <ResultEntriesForm actionRef={modalRefEntries} />
      {/* <Modal
        centered={true}
        title={"Send for Validation"}
        visible={visibleValidation}
        onOk={handleSubmitValidation}
        onCancel={handleCancelValidation}
        // cancelButtonProps={{ style: { display: "none" } }}
        okText={t("BUTTON.save")}
        okButtonProps={{ htmlType: "submit" }}
        width={1000}
        forceRender
      >
        <Row>
          <Col span={6}>Validation Process</Col>
          <Col span={18}>
            <Radio.Group onChange={onChange} value={value}>
              <Radio value={1}>A</Radio>
              <Radio value={2}>B</Radio>
            </Radio.Group>
          </Col>
        </Row>
      </Modal> */}
      <ModalValidation
        actionRef={modalValidationRef}
        reset={() => reset()}
      />
      <ModalValidation2
        actionRef={modalValidationRef2}
        reset={() => reset()}
      />
      <ModalReturnResults
        actionRef={modalValidationRef3}
        reset={() => reset()}
      />
      <Modal
        title={t("MODAL.export")}
        visible={visiblePdf}
        centered
        width={"100%"}
        bodyStyle={{ height: "90vh" }}
        footer={null}
        onCancel={() => {
          setVisiblePdf(false)
        }}
      >
        <PDFViewer style={{ width: "100%", height: "calc(100% - 40px)" }}>
          <TestPdfView data={dataSelected} />
        </PDFViewer>
      </Modal>
    </div>
  );
};

export default Test;
