import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Page, Text, View, Document, StyleSheet, Font, Image } from '@react-pdf/renderer';
import TablePdf from "./TablePdf";
import { timeFormatter } from "../../utils/utils";
import { CONFIG } from '../../configs';

// Register font
Font.register({ family: 'Times-new-roman', src: '/fonts/font-times-new-roman.ttf' });
Font.register({ family: 'Times-new-roman-bold', src: '/fonts/font-times-new-roman-bold.ttf' });
Font.register({ family: 'Times-new-roman-italic', src: '/fonts/Times-New-Roman-Italic.ttf' });

const monthArr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

const BarcodePdfView = ({ data }) => {
  const [dataTable, setDataTable] = useState([])
  // console.log(`data`, data)

  const handleConvertDataTable = () => {
    let result = []
    if (data.length > 0) {
      data.map((item, index) => {
        const row = {
          stt: index + 1,
          sampleName: item.sampleName,
          productionDate: timeFormatter(item.productionDate),
          packedSize: item.packedSize,
          currentQuantity: item.currentQuantity,
          producerName: item.producerName,
          producerAddress: item.producerAddress,
          status: item.status,
          substanceName: item.substanceName,
          substanceContent: item.substanceContent,
          registerNumber: item.registerNumber
        }
        result.push(row)
      })
    }

    setDataTable(result)
  }

  useEffect(() => {
    handleConvertDataTable()
  }, [data])

  return (
    <Document>
      <Page size="A4" style={styles.page} orientation={'landscape'}>
        <View style={styles.section}>


          {/* CONTENT */}
          <View style={{ textAlign: "center" }}>
            <Text><Image src={CONFIG.API_URL.BASE + data?.[0]?.barcode} style={{ width: "300px", height: "300px" }} /></Text>
            <Text>{data?.[0]?.sampleIdExternal}</Text>

          </View>
          {/* CONTENT */}

        </View>
      </Page>
    </Document>
  );
};

export default BarcodePdfView;


// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#fff',
    fontFamily: 'Times-new-roman',
    fontStyle: 'normal',
    fontSize: 12,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  row: {
    display: 'flex',
    flexFlow: 'row wrap',
  },
  logo: {
    width: '60px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  bold: {
    fontFamily: 'Times-new-roman-bold',
  },
  italic: {
    fontFamily: 'Times-new-roman-italic',
  },
  underline: {
    borderBottom: '0.5px solid black',
    display: 'inline-block'
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'between',
    alignItems: 'between',
    marginTop: '5px'
  },
  footerItem: {
    width: '33.3333%',
    textAlign: 'center'
  }
});

const header = StyleSheet.create({
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: '10px'
  },
  item1: {
    display: 'block',
    flex: '0 0 30%',
    maxWidth: '30%',
    textAlign: 'center',
    justifyContent: 'center'
  },
  item2: {
    display: 'block',
    flex: '0 0 70%',
    maxWidth: '70%',
  },
  top: {
    textAlign: 'center'
  },
  title: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginTop: '10px'
  }
})
