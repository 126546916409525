import React from 'react';
import {Row, Col} from 'antd';
import { WarningOutlined } from '@ant-design/icons';

const NotAuthorizePage = () => (
    <Row gutter={[24, 16]} justify="center" align="middle" style={{height: '100%'}}>
        <Col span={4}>
            <h1 style={{textAlign: 'right', fontSize: '100px', color: '#f39c12'}}>400</h1>
        </Col>
        <Col span={8}>
            <WarningOutlined style={{color: '#f39c12'}} /> Oops! All rights reserved.
            <p>The webmaster said that you can not enter this page...</p>
            <p>Please check that the URL you entered is correct, or click the button below to return to the homepage.</p>
        </Col>
    </Row>
);

export default NotAuthorizePage;
