import React, { Fragment } from "react";
import { Row, Divider, Input, Form, Col, Button } from "antd";
import FormSection from "src/components/FormSection";
import { useTranslation } from "react-i18next";
import { FieldsRenderComponent } from "./filedRender";
import Map from "./Map";

const SectionRender = (props) => {
  const {
    item,
    ishowDrug,
    onSearch,
    onSearchCustodianName,
    positionSample,
    setPositionSample,
    setConfigFirst,
    deleteConfig,
    handleChangeForm,
  } = props;
  const data = Object.entries(item.attribute);
  const { t } = useTranslation();

  //Custom for samplerInformation section
  if (item.display && item.sectionName === "samplerInformation") {
    if (Array.isArray(item?.attribute)) {
      return (
        <FormSection header={t(`SAMPLE.FORM.${item.sectionName}`)}>
          <Form.List name="samplerInformation">
            {(fields) => (
              <>
                {item?.attribute.map((field, index) => {
                  return (
                    <Fragment key={index}>
                      <Row gutter={[32, 8]}>
                        {Object.entries(field).map((val, idx) => {
                          val[0] = [fields[index]?.name, val[0]];
                          if (val[0][1] === "samplerUserId") return;
                          return (
                            <FieldsRenderComponent
                              key={idx}
                              item={val}
                              onUpload={props.onUpload}
                              onSearch={onSearch}
                              sectionName={item.sectionName}
                              positionSample={positionSample}
                              setPositionSample={setPositionSample}
                              handleChangeForm={handleChangeForm}
                            />
                          );
                        })}
                      </Row>
                      <Divider className="tw-mt-1" />
                    </Fragment>
                  );
                })}
              </>
            )}
          </Form.List>
        </FormSection>
      );
    } else {
      <FormSection header={t(`SAMPLE.FORM.${item.sectionName}`)}>
        <Row gutter={[32, 8]}>
          {data.map((item, idx) => {
            if (props?.hiddenInputs?.includes(item[0])) return;
            if (
              (item[0] === "importFacility" && !ishowDrug) ||
              item[0] === "samplerUserId"
            ) {
              return;
            }
            return (
              <FieldsRenderComponent
                key={idx}
                item={item}
                onUpload={props.onUpload}
                onSearch={onSearch}
                positionSample={positionSample}
                setPositionSample={setPositionSample}
                handleChangeForm={handleChangeForm}
              />
            );
          })}
        </Row>
      </FormSection>;
    }
  }
  //Custom for substanceInformation section
  if (
    item.display &&
    item.sectionName === "substanceInformation" &&
    Array.isArray(item?.attribute)
  ) {
    return (
      <FormSection header={t(`SAMPLE.FORM.${item.sectionName}`)}>
        <Form.List name="substanceInformation">
          {(fields, { add, remove }) => (
            <>
              {item?.attribute.map((field, index) => {
                return (
                  <Fragment key={index}>
                    <Row gutter={[32, 8]}>
                      {Object.entries(field).map((val, idx) => {
                        val[0] = [fields[index]?.name || 0, val[0]];
                        if (val[0][1] === "action") {
                          return (
                            <Col span={12}>
                              {(fields.length === 1 ||
                                fields.length === 0 ||
                                fields[index]?.name === fields.length - 1) && (
                                <Button
                                  style={{ marginRight: "0.5rem" }}
                                  type="primary"
                                  onClick={() => {
                                    setConfigFirst("substanceInformation");
                                    add();
                                  }}
                                >
                                  {" "}
                                  + Thêm hợp chất
                                </Button>
                              )}
                              {fields.length > 1 && (
                                <Button
                                  type="dashed"
                                  onClick={() =>
                                    deleteConfig(
                                      fields[index]?.name,
                                      "substanceInformation"
                                    )
                                  }
                                >
                                  {" "}
                                  Xóa
                                </Button>
                              )}
                            </Col>
                          );
                        }
                        return (
                          <FieldsRenderComponent
                            key={idx}
                            item={val}
                            onUpload={props.onUpload}
                            onSearch={onSearch}
                            sectionName={item.sectionName}
                            positionSample={positionSample}
                            setPositionSample={setPositionSample}
                            handleChangeForm={handleChangeForm}
                          />
                        );
                      })}
                    </Row>
                    <Divider className="tw-mt-1" />
                  </Fragment>
                );
              })}
            </>
          )}
        </Form.List>
      </FormSection>
    );
  }

  //Custom for targetInformation section
  if (
    item.display &&
    item.sectionName === "targetInformation" &&
    Array.isArray(item?.attribute)
  ) {
    return (
      <FormSection header={t("SAMPLE.FORM.targetInformation")}>
        <Form.List name="targetInformation">
          {(fields, { add, remove }) => (
            <>
              {item?.attribute.map((field, index) => {
                return (
                  <Fragment key={index}>
                    <Row gutter={[32, 8]}>
                      {Object.entries(field).map((val, idx) => {
                        val[0] = [fields[index]?.name || 0, val[0]];
                        if (val[0][1] === "action") {
                          return (
                            <Col span={12}>
                              {(fields.length === 1 ||
                                fields.length === 0 ||
                                fields[index]?.name === fields.length - 1) && (
                                <Button
                                  style={{ marginRight: "0.5rem" }}
                                  type="primary"
                                  onClick={() => {
                                    setConfigFirst("targetInformation");
                                    add();
                                  }}
                                >
                                  {" "}
                                  + Thêm chỉ tiêu
                                </Button>
                              )}
                              {fields.length > 1 && (
                                <Button
                                  type="dashed"
                                  onClick={() =>
                                    deleteConfig(
                                      fields[index]?.name,
                                      "targetInformation"
                                    )
                                  }
                                >
                                  {" "}
                                  Xóa
                                </Button>
                              )}
                            </Col>
                          );
                        }
                        return (
                          <FieldsRenderComponent
                            key={idx}
                            item={val}
                            onUpload={props.onUpload}
                            onSearch={onSearch}
                            sectionName={item.sectionName}
                            positionSample={positionSample}
                            setPositionSample={setPositionSample}
                            handleChangeForm={handleChangeForm}
                          />
                        );
                      })}
                    </Row>
                    <Divider className="tw-mt-1" />
                  </Fragment>
                );
              })}
            </>
          )}
        </Form.List>
      </FormSection>
    );
  }

  return item.display ? (
    <FormSection
      header={t(`SAMPLE.FORM.${item.sectionNameLocize ?? item.sectionName}`)}
    >
      <Row gutter={[32, 8]}>
        {data.map((item, idx) => {
          if (props?.hiddenInputs?.includes(item[0])) return;
          if (item[0] === "importFacility" && !ishowDrug) {
            return;
          }
          return (
            <FieldsRenderComponent
              key={idx}
              item={item}
              onUpload={props.onUpload}
              onSearch={onSearch}
              onSearchCustodianName={onSearchCustodianName}
              positionSample={positionSample}
              setPositionSample={setPositionSample}
              handleChangeForm={handleChangeForm}
            />
          );
        })}
      </Row>
      {item.sectionName == "pickingInformation" && (
        <Map
          locations={[
            {
              id: 1,
              latitude: 21.0228161,
              longitude: 105.8528011,
              zoom: 14,
              ...positionSample,
            },
          ]}
        />
      )}
    </FormSection>
  ) : null;
};

export default SectionRender;
