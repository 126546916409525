import { View, Text } from "@react-pdf/renderer";
import { styles } from "../style";

export function ReportName(props) {
  const reportName = props.configObject?.reportName || "";
  return (
    <View style={{ margin: "10px" }}>
      {!props.disableTop && (
        <Text style={[styles.bold, styles.textCenter, styles.fS16]}>
          BIÊN BẢN
        </Text>
      )}

      <Text style={[styles.bold, styles.textCenter, styles.fS16]}>
        {reportName}
      </Text>
      <View>{props.children}</View>
    </View>
  );
}
