import { Document, PDFViewer, Page, View, Text } from "@react-pdf/renderer";
import React from "react";
import { TitleLeft, TitleRight } from "../Common/Components/title";
import PdfTable from "../Common/Components/pdfTable";
import { styles } from "../Common/style";
import { Footer, RenderSigns } from "../Common/Components/footer";
import { ReportName } from "../Common/Components/reportName";

export default function BienBanBanGiaoMau(props) {
  //Ngang
  const { currentDate, code } = props;
  return (
    <PDFViewer style={{ width: "100%", height: "calc(100% - 40px)" }}>
      <Document>
        <Page size="A4" style={[styles.page]} orientation={"landscape"}>
          <View style={styles.section}>
            <View style={styles.row}>
              <View style={[styles.col_30, styles.textCenter]}>
                <TitleLeft />
              </View>
              <View style={styles.col_20}></View>
              <View style={[styles.col_50, styles.textCenter]}>
                <TitleRight />
              </View>
            </View>
            <ReportName {...props} disableTop></ReportName>
            <Information1 {...props} />
            <View style={{ marginBottom: "5px" }}></View>
            <PdfTable {...props} />
            <View style={{ marginBottom: "5px" }}></View>
            <Information2 {...props} />
          </View>
          {/*  */}
          <Footer {...props} />
        </Page>
      </Document>
    </PDFViewer>
  );
}

function Information1(props) {
  const { data } = props;
  //const info = data[0] || {};
  const samplers = [
    ...new Set(
      data
        .flatMap((x) => x.samplerInformation)
        .filter((x) => x?.samplerName)
        .map(
          (x) => "Ông/bà: " + x.samplerName + ", chức vụ: " + x?.samplerPosition
        )
    ),
  ];

  return (
    <View>
      <Text>
        Hôm nay, vào lúc ..... giờ ..... phút, ngày ... tháng ... năm ..... .
        Tại Kho lưu mẫu - Trung tâm Kiểm nghiệm thuốc, mỹ phẩm, thực phẩm tỉnh
        Yên Bái.
      </Text>
      <Text>Chúng tôi gồm:</Text>
      {samplers.map((sampler, index) => {
        return (
          <Text>
            {index + 1}. {sampler}
          </Text>
        );
      })}

      <Text>
        Tiến hành bàn giao ..... mẫu thuốc để xác định chất lượng, cụ thể như
        sau:
      </Text>
    </View>
  );
}

function Information2(props) {
  const { data, currentSubStanceType } = props;
  const info = data[0] || {};
  let signs = ["NGƯỜI NHẬN MẪU", "NGƯỜI BÀN GIAO MẪU"];

  return (
    <>
      {/* footer info */}
      <View>
        <Text>
          Biên bản được lập xong lúc ..... giờ ..... phút, ngày .. tháng .. năm
          .... .
        </Text>

        <Text>
          Biên bản bàn giao này được lập thành ..... bản có giá trị như nhau.
          Bên bàn giao giữ ..... bản và cán bộ tiếp nhận mẫu của phòng
        </Text>
        <Text>Kế hoạch giữ ..... bản.</Text>
      </View>
      {/* footer signs */}
      <RenderSigns titles={signs} bellow={false} />
    </>
  );
}
