import { readMoney } from "../../../../../utils/utils";

export const monthArr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export const toShortDate = (d) => {
  const date = d ? new Date(d) : new Date();
  const day = ("0" + date.getDate()).slice(-2);
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const year = date.getFullYear().toString().substr(-2);
  return `${day}/${month}/${year}`;
};

export function toVnMoney(inputString) {
  inputString = inputString || "";
  const inputInt = parseInt(inputString) || 0;

  return (
    <>
      {" "}
      {inputInt.toLocaleString("it-IT", {
        style: "currency",
        currency: "VND",
      })}{" "}
      ({readMoney(inputString)})
    </>
  );
}

export const toVNDate = (d) => {
  const date = d ? new Date(d) : new Date();
  return `ngày ${("0" + date.getDate()).slice(-2)} tháng ${(
    "0" +
    (date.getMonth() + 1)
  ).slice(-2)} năm ${date.getFullYear()}`;
};

export const toQuarter = (d) => {
  const date = d ? new Date(d) : new Date();
  var quarter = Math.floor((date.getMonth() + 3) / 3);
  return `qúy ${quarter} năm ${date.getFullYear()}`;
};

export const toMonthAndFullYear = (d) => {
  const date = d ? new Date(d) : new Date();
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  return `tháng ${month} năm ${date.getFullYear()}`;
};

export const writeTextWithPrefix = (text, prefix = "-") => {
  return text ? `${prefix} ${text}` : "";
};
