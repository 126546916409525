import { CONFIG } from "../../configs";
import axiosService from "../../services/axiosService";

export const getSampleConfigByKey = (key) => {
  return axiosService.get(
    `${CONFIG.API_URL.BASEDOTNET}/api/sample/config?key=${key}`
  );
};

export const saveSampleConfig = async (data) => {
  return axiosService.put(
    `${CONFIG.API_URL.BASEDOTNET}/api/sample/config`,
    data
  );
};

export const deleteSampleConfig = async (data) => {
  return axiosService.delete(
    `${CONFIG.API_URL.BASEDOTNET}/api/sample/config`,
    undefined,
    data
  );
};

export const apiDeleteSaveConfig = async (ids) => {
  return axiosService.delete(
    `${CONFIG.API_URL.BASE}/api/samples/saveConfig?ids=${ids}`
  );
};

export const apiEditSaveConfig = async (id, data) => {
  return axiosService.put(
    `${CONFIG.API_URL.BASE}/api/samples/saveConfig/${id}`,
    data
  );
};

export const getSampleOrder = (key) => {
  return axiosService.get(
    `${CONFIG.API_URL.BASE}/api/samples/sampleOrder?contractNumber=${key}`
  );
};

export const getSampleInternalId = () => {
  return axiosService.get(
    `${CONFIG.API_URL.BASE}/api/samples/sampleInternalId`
  );
};
