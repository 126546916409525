import { Button, Col, DatePicker, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import {
  getExportSummaryDataReport,
  getExportSummaryDataReportPdf,
  getSummaryDataReport,
} from "../../../apis/samples";
import { getListTemplate } from "../../../apis/Template";
import ChartMixed from "./components/ChartMixed";
import ChartStackedColumn from "./components/ChartStackedColumn";
import moment from "moment-timezone";

const Report = () => {
  const [data, setData] = useState([]);
  const [selectedSampleType, setSelectedSampleType] = useState();
  const [dataSampleType, setDataSampleType] = useState([]);
  const [startDate, setStartDate] = useState(
    moment("2022-01-01", "YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(moment());

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const sD = startDate.format("YYYY-MM-DD");
    const eD = endDate.format("YYYY-MM-DD");
    const [dataSummary, dataSampleType] = await Promise.all([
      getSummaryDataReport(sD, eD),
      getListTemplate("SAMPLE"),
    ]);
    console.log("dataSummary.data", dataSummary.data);
    let d = [...dataSummary.data];
    d = d.map((x) => {
      if (!x?.importOrigin) {
        x.importOrigin = "Không rõ nguồn gốc";
      }
      if (!x?.substanceType) {
        x.substanceType = "Khác";
      }
      return x;
    });
    setData(d);
    setSelectedSampleType(
      (dataSampleType.data || []).map((x) => x?.templateName)
    );
    setDataSampleType(dataSampleType?.data || []);
    let sT = [];
    (dataSummary.data || []).forEach((value) => {
      if (sT.includes(value?.templateName)) return;
      sT.push(value?.templateName);
    });
  };

  const onChange = (v) => setSelectedSampleType(v);

  const onExport = async (e) => {
    const sD = startDate.format("YYYY-MM-DD");
    const eD = endDate.format("YYYY-MM-DD");
    await getExportSummaryDataReport(
      sD,
      eD,
      selectedSampleType
        .map((item) => {
          const selected = dataSampleType.find((x) => x.templateName === item);
          return selected?.id;
        })
        .toString()
    );
  };

  const onExportPdf = async (e) => {
    const sD = startDate.format("YYYY-MM-DD");
    const eD = endDate.format("YYYY-MM-DD");
    await getExportSummaryDataReportPdf(
      sD,
      eD,
      selectedSampleType
        .map((item) => {
          const selected = dataSampleType.find((x) => x.templateName === item);
          return selected?.id;
        })
        .toString()
    );
  };

  const onFilter = () => {
    getData();
  };

  const style = {
    padding: "0.5rem",
  };
  const styleHeading = {
    fontWeight: "bold",
    fontSize: "20px",
  };

  return (
    <div
      style={{
        background: "white",
      }}
    >
      <Row style={style}>
        <Col span={10}>
          <Row>
            <Col span={4}>Loại mẫu:</Col>
            <Col span={20}>
              <Select
                mode="multiple"
                value={selectedSampleType}
                allowClear
                placeholder="Loại mẫu..."
                onChange={onChange}
              >
                {dataSampleType.map((sT) => (
                  <Select.Option key={sT.templateName}>
                    {sT.templateName}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row>
            <Col span={4} style={{ paddingLeft: "0.5rem" }}>
              Từ ngày
            </Col>
            <Col span={8}>
              <DatePicker
                placeholder={""}
                className={"tw-w-full"}
                format={"DD/MM/YYYY"}
                value={startDate}
                onChange={(e) => setStartDate(e)}
              />
            </Col>
            <Col span={4} style={{ paddingLeft: "0.5rem" }}>
              Đến ngày
            </Col>
            <Col span={8}>
              <DatePicker
                placeholder={""}
                className={"tw-w-full"}
                format={"DD/MM/YYYY"}
                value={endDate}
                onChange={setEndDate}
              />
            </Col>
          </Row>
        </Col>
        <Col span={6}>
          <Button type="" onClick={onFilter} style={{ marginLeft: "0.5rem" }}>
            Xem
          </Button>
          <Button
            type="primary"
            onClick={onExport}
            style={{ marginLeft: "0.5rem" }}
          >
            Xuất (EXCEL)
          </Button>
          <Button
            type="primary"
            onClick={onExportPdf}
            style={{ marginLeft: "0.5rem" }}
          >
            Xuất (PDF)
          </Button>
        </Col>
      </Row>
      {(selectedSampleType || []).map((sT) => {
        const d = data.filter((value) => value.templateName === sT);
        const months = [];
        d.forEach((value) => {
          if (months.includes(value?.monthName)) return;
          months.push(value?.monthName);
        });
        return (
          <Row style={style} key={sT}>
            <Col span={24}>
              <h1 style={styleHeading}>{sT}</h1>
            </Col>
            <Col span={18} offset={3}>
              {d.length <= months.length || false ? (
                <ChartStackedColumn data={d} />
              ) : (
                <ChartMixed data={d} />
              )}
              {/* <ChartMixed data={d} /> */}
              {/* <ChartStackedColumn data={d} /> */}
            </Col>
          </Row>
        );
      })}
    </div>
  );
};

export default Report;
