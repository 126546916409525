export const styles = {
  viewCenter: {
    display: "flex",
    justifyContent: "center",
  },
  page: {
    backgroundColor: "#fff",
    fontFamily: "TimeNewRomanSSS",
    fontStyle: "normal",
    fontSize: 12,
    padding: "2cm",
    lineHeight: "1.5px",
  },
  page1Cm: {
    backgroundColor: "#fff",
    fontFamily: "TimeNewRomanSSS",
    fontStyle: "normal",
    fontSize: 12,
    padding: "1cm",
    lineHeight: "1.5px",
  },
  pageBook: {
    backgroundColor: "#fff",
    fontFamily: "TimeNewRomanSSS",
    fontStyle: "normal",
    fontSize: 13,
    padding: "1.5cm 0.5cm 1.5cm 0.5cm",
  },
  section: {
    flexGrow: 1,
    display: "flex",
  },
  logo: {
    width: "60px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  bold: {
    fontFamily: "TimeNewRomanSSS",
    fontWeight: "bold",
  },
  italic: {
    fontFamily: "TimeNewRomanSSS",
    fontStyle: "italic",
  },
  bold_italic: {
    fontWeight: "bold",
    fontStyle: "italic",
    fontFamily: "TimeNewRomanSSS",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: "0.6cm",
    right: "50%",
    fontWeight: "bold",
    opacity: 0.5,
  },
  pageEnd: {
    position: "absolute",
    fontSize: 12,
    bottom: "0.6cm",
    right: "1cm",
    fontWeight: "bold",
    opacity: 0.5,
  },
  pageCode: {
    position: "absolute",
    fontSize: 12,
    bottom: "0.6cm",
    left: "1cm",
    fontWeight: "bold",
    opacity: 0.5,
  },
  underline: {
    borderBottom: "0.5px solid black",
    margin: "2px auto 2px auto",
  },
  textCenter: {
    textAlign: "center",
  },
  textRight: {
    textAlign: "right",
  },
  //#region ROW & COL
  row: {
    display: "flex",
    flexDirection: "row",
  },
  col_10: {
    flex: "0 0 10%",
  },
  col_20: {
    flex: "0 0 20%",
  },
  col_25: {
    flex: "0 0 25%",
  },
  col_30: {
    flex: "0 0 30%",
  },
  col_33: {
    flex: "0 0 33.3333%",
  },
  col_40: {
    flex: "0 0 40%",
  },
  col_50: {
    flex: "0 0 50%",
  },
  col_60: {
    flex: "0 0 60%",
  },
  col_66: {
    flex: "0 0 66.6666%",
  },
  col_70: {
    flex: "0 0 70%",
  },
  col_80: {
    flex: "0 0 80%",
  },
  col_90: {
    flex: "0 0 90%",
  },
  col_100: {
    flex: "0 0 100%",
  },
  //#endregion

  //#region TABLE

  tableRow: { margin: "auto", flexDirection: "row" },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "center",
  },
  tableColFirst: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderTopWidth: 0,
    textAlign: "center",
  },
  tableCell: {
    // margin: "auto",
    padding: 2,
    // marginTop: 5,
    // marginBottom: 5,
    fontSize: 10,
  },

  //#endregion

  fS13: { fontSize: "13px" },
  fS16: { fontSize: "16px" },
  fS12: { fontSize: "12px" },
  fS11: { fontSize: "11px" },
  fS10: { fontSize: "10px" },
};
