import {
  Col,
  Form,
  Input,
  DatePicker,
  Select,
  InputNumber,
  Radio,
  Typography,
} from "antd";
import i18next from "i18next";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { TYPE } from "../../../constants/constant";
import { UploadStyled } from "../../../constants/styledComponent.constant";
import UploadSingle from "../../../components/Upload/UploadSingle";
import "antd/dist/antd.css";
import { CONFIG } from "../../../configs";
import styled from "styled-components";
import SearchDocumentInput from "../../Document/Components/SearchDocumentInput";
import { getGeoLocation } from "../../../apis/geolocation";
import DropdownlistCRUD from "./DropdownlistCRUD";
import { requireFields } from "../form";

const FIELD_DDL_CRUDS = [
  "dosageForm",
  "samplerRequired",
  "status",
  "sampleRetentionTime",
  "testMethod",
  "packedSize",
  "sensoryAssessment",
  "commune",
  "ward",
  "province",
  "locationName",
  "representativePerson",
  "pickingSellType",
  "unit",
  "producerName",
  "producerAddress",
  "z3", //Hình thức trả kết quả
];

const ARRAY_FIELD_DDL_CRUDS = [
  {
    key: "substanceInformation",
    values: ["substanceUnit"],
  },
  {
    key: "targetInformation",
    values: ["targetName", "targetUnit", "targetMethod"],
  },
];

export const FieldsRenderComponent = (props) => {
  const {
    item,
    fieldStyles,
    hidden,
    onUpload,
    onSearch,
    sectionName,
    onSearchCustodianName,
    positionSample = {},
    setPositionSample,
    handleChangeForm,
  } = props;

  const { t } = useTranslation();
  const ruleRequire = [{ required: false, message: t("VALIDATION.require") }];

  const checkAddon = (val) => {
    switch (val) {
      case "temperature":
        return <>&#8451;</>;
      case "humidity":
        return "%";
      default:
        return null;
    }
  };

  const handleAddress = async (value) => {
    const { data } = await getGeoLocation(value);
    setPositionSample({
      latitude: data?.data?.[0]?.latitude,
      longitude: data?.data?.[0]?.longitude,
    });
  };

  const [dataMaps, setDataMaps] = useState([]);

  const [options, setOptions] = useState([]);
  const [showDrop, setShowDrop] = useState(false);

  const handleSearch = async (value) => {
    if (!value) return;
    setShowDrop(true);
    const { data } = await getGeoLocation(value.replace(/[0-9]/g, ""));
    let o = data?.data || [];
    o = o.map((d) => {
      return {
        ...d,
        label: d?.street ? d?.name + ", " + d?.street : d.label,
      };
    });
    setDataMaps(o);
    const options =
      o.map((d) => {
        return {
          value: d.label,
          label: d.label,
        };
      }) || [];
    setOptions(options);
  };

  const onSelect = (value) => {
    const data = dataMaps.find((x) => x.label === value);
    setPositionSample({
      latitude: data?.latitude || 16.0546891,
      longitude: data?.longitude || 108.2033023,
    });
    // handleChangeForm && handleChangeForm("pickingAddress", preValueSearch)
  };

  const checkIsPhone = (value) => {
    return /[0-9]/.test(value);
  };

  const onChange = (value, key) => {
    if (item[0] === "documentName") {
      handleChangeForm && handleChangeForm("documentId", key);
    }
  };

  if (
    [
      "drugNameRelatedDocs",
      "sampleNameRelatedDocs",
      "registrationNumberRelatedDocs",
      "documentName",
    ].includes(item[0])
  ) {
    let disabled = item[1].disabled === true;
    return (
      <Col span={12}>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => {
            const formItemValue = getFieldValue(item[0]);
            let defaultOptions = [];
            if (formItemValue) {
              defaultOptions = [
                { value: getFieldValue(item[0]), text: getFieldValue(item[0]) },
              ];
            }

            return (
              <Form.Item
                label={t(`SAMPLE.FORM.${item[0]}`)}
                name={item[0]}
                labelAlign={"left"}
                rules={ruleRequire}
                style={{ ...fieldStyles }}
                hidden={hidden}
                initialValue={formItemValue}
                className={`form-item__${item[0]} ${
                  requireFields.includes(item[0]) ? "before-form-item" : ""
                }`}
              >
                <SearchDocumentInput
                  defaultValue={formItemValue}
                  options={defaultOptions}
                  disabled={disabled}
                  placeholder={i18next.t("DOCUMENT.SAMPLE.related_document")}
                  onChange={onChange}
                />
              </Form.Item>
            );
          }}
        </Form.Item>
      </Col>
    );
  }

  if (item[0][1] === "drugNameRelatedDocs") {
    let disabled = item[1].disabled === true;
    return (
      <Col span={12}>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => {
            const formItemValue = getFieldValue("substanceInformation")?.[
              item[0][0]
            ]?.[item[0][1]];
            let defaultOptions = [];
            if (formItemValue) {
              defaultOptions = [{ value: formItemValue, text: formItemValue }];
            }

            return (
              <Form.Item
                label={t(`SAMPLE.FORM.${item[0][1]}`)}
                name={item[0]}
                labelAlign={"left"}
                rules={ruleRequire}
                style={{ ...fieldStyles }}
                hidden={hidden}
                initialValue={formItemValue}
                className={`form-item__${item[0][1]} ${
                  requireFields.includes(item[0][1]) ? "before-form-item" : ""
                }`}
              >
                <SearchDocumentInput
                  defaultValue={formItemValue}
                  options={defaultOptions}
                  disabled={disabled}
                  placeholder={i18next.t("DOCUMENT.SAMPLE.related_document")}
                />
              </Form.Item>
            );
          }}
        </Form.Item>
      </Col>
    );
  }

  let ddlCrudFieldName = "";
  let ddlCrudFieldNameSpecial;

  const arrayFieldCRUD = ARRAY_FIELD_DDL_CRUDS.find(
    (x) => x.key === sectionName
  );

  const fieldDDLCRUD = item[0] || "";
  const arFieldDDLCRUD = item[0][1] || "";

  if (arrayFieldCRUD && arrayFieldCRUD.values.includes(arFieldDDLCRUD)) {
    ddlCrudFieldName = arFieldDDLCRUD;
    ddlCrudFieldNameSpecial = item[0];
  } else if (FIELD_DDL_CRUDS.includes(fieldDDLCRUD)) {
    ddlCrudFieldName = fieldDDLCRUD;
  }

  if (ddlCrudFieldName) {
    return (
      <Col span={12}>
        <Form.Item noStyle shouldUpdate>
          {() => {
            return (
              <Form.Item
                label={t(`SAMPLE.FORM.${ddlCrudFieldName}`)}
                name={ddlCrudFieldNameSpecial || ddlCrudFieldName}
                labelAlign={"left"}
                rules={ruleRequire}
                style={{ ...fieldStyles }}
              >
                <DropdownlistCRUD
                  field={ddlCrudFieldName}
                  onChange={() => {}}
                />
              </Form.Item>
            );
          }}
        </Form.Item>
      </Col>
    );
  }

  const renderByType = (item) => {
    let disabled = item[1].disabled == true;
    if (item[0] == "position") {
      const v = positionSample.latitude
        ? `${positionSample.latitude},${positionSample.longitude}`
        : "";
      return (
        <Form.Item
          label={t(`SAMPLE.FORM.${item[0]}`)}
          name={item[0]}
          labelAlign={"left"}
          rules={ruleRequire}
          style={{ ...fieldStyles }}
          hidden={hidden}
        >
          <div style={{ display: "none" }}> {positionSample.latitude}</div>
          <Input disabled={true} value={v} />
        </Form.Item>
      );
    }

    switch (item[1].type) {
      case TYPE.textbox:
        if (sectionName === "samplerInformation") {
          return (
            <Form.Item
              label={t(`SAMPLE.FORM.${item[0][1]}`)}
              name={item[0]}
              labelAlign={"left"}
              rules={ruleRequire}
              style={{ ...fieldStyles }}
              hidden={hidden}
              className={`form-item__${item[0][1]} ${
                requireFields.includes(item[0][1]) ? "before-form-item" : ""
              }`}
            >
              {item[0][1] === "samplerName" ? (
                <Input.Search disabled={disabled} onSearch={onSearch} />
              ) : (
                <Input disabled={true} />
              )}
            </Form.Item>
          );
        }
        if (
          sectionName === "substanceInformation" ||
          sectionName === "targetInformation"
        ) {
          return (
            <Form.Item
              label={t(`SAMPLE.FORM.${item[0][1]}`)}
              name={item[0]}
              labelAlign={"left"}
              rules={ruleRequire}
              style={{ ...fieldStyles }}
              hidden={hidden}
              //className={`form-item__${item[0][1]}`}
              className={`form-item__${item[0][1]} ${
                requireFields.includes(item[0][1]) ? "before-form-item" : ""
              }`}
            >
              <Input />
            </Form.Item>
          );
        }
        if (item[0] === "pickingAddress") {
          return (
            <>
              <Form.Item
                label={t(`SAMPLE.FORM.${item[0]}`)}
                name={item[0]}
                labelAlign={"left"}
                rules={ruleRequire}
                style={{ ...fieldStyles }}
                hidden={hidden}
                className={`form-item__${item[0]} ${
                  requireFields.includes(item[0]) ? "before-form-item" : ""
                }`}
              >
                <Input.Search onSearch={handleSearch} enterButton />
                {/* <Input.Search size="large" placeholder="" enterButton onSearch={handleSearch} /> */}

                {/* <AutoComplete
                dropdownMatchSelectWidth={252}
                style={{ width: 300 }}
                options={options}
                onSelect={onSelect}
              // onSearch={handleSearch}
              >
                <Input.Search size="large" placeholder="" enterButton onSearch={handleSearch} />
              </AutoComplete> */}
              </Form.Item>
              {showDrop && (
                <div
                  style={{
                    position: "absolute",
                    zIndex: 100,
                    background: "white",
                    maxHeight: "127px",
                    width: "240px",
                    overflowX: "hidden",
                    top: "35px",
                    right: "10px",
                  }}
                >
                  {options.map((o) => {
                    return (
                      <Typography.Text
                        className="input-dropdown-search"
                        ellipsis
                        title={o.value}
                        onClick={() => {
                          setShowDrop(false);
                          onSelect(o.value);
                        }}
                      >
                        {o.label}
                      </Typography.Text>
                    );
                  })}
                </div>
              )}
            </>
          );
        }
        return (
          <Form.Item
            label={t(`SAMPLE.FORM.${item[0]}`)}
            name={item[0]}
            labelAlign={"left"}
            rules={ruleRequire}
            style={{ ...fieldStyles }}
            hidden={item[0] === "custodianUserId" || hidden}
            className={`form-item__${item[0]} ${
              requireFields.includes(item[0]) ? "before-form-item" : ""
            }`}
          >
            {item[0] === "samplerName" || item[0] === "custodianName" ? (
              <Input.Search
                disabled={disabled}
                onSearch={
                  item[0] === "custodianName" ? onSearchCustodianName : onSearch
                }
              />
            ) : (
              <Input
                disabled={disabled}
                onBlur={(e) => {
                  if (item[0] == "pickingAddress" && e.target.value) {
                    handleAddress(e.target.value);
                  }
                }}
                onKeyPress={(e) => {
                  if (
                    e.key === "Enter" &&
                    item[0] === "pickingAddress" &&
                    e.target.value
                  ) {
                    handleAddress(e.target.value);
                  } else if (
                    item[0] === "pickingPhone" ||
                    item[0] === "customerPhone"
                  ) {
                    if (!checkIsPhone(e.key)) {
                      e.preventDefault();
                    }
                  }
                }}
              />
            )}
          </Form.Item>
        );
      case TYPE.checkbox:
        return (
          <Form.Item
            label={t(`SAMPLE.FORM.${item[0]}`)}
            name={item[0]}
            labelAlign={"left"}
            rules={ruleRequire}
            style={{ ...fieldStyles }}
            hidden={hidden}
            className={`form-item__${item[0]} ${
              requireFields.includes(item[0]) ? "before-form-item" : ""
            }`}
          >
            <Radio.Group disabled={disabled}>
              <Radio value={true}>{t("BUTTON.yes")}</Radio>
              <Radio value={false}>{t("BUTTON.no")}</Radio>
            </Radio.Group>
          </Form.Item>
        );
      case TYPE.number:
        if (
          sectionName === "substanceInformation" ||
          sectionName === "targetInformation"
        ) {
          return (
            <Form.Item
              label={t(`SAMPLE.FORM.${item[0][1]}`)}
              name={item[0]}
              labelAlign={"left"}
              className={`form-item__${item[0][1]} ${
                requireFields.includes(item[0][1]) ? "before-form-item" : ""
              }`}
            >
              <InputNumber
                disabled={disabled}
                addonAfter={checkAddon(item[0][1])}
              />
            </Form.Item>
          );
        }
        return (
          <Form.Item
            label={t(`SAMPLE.FORM.${item[0]}`)}
            name={item[0]}
            labelAlign={"left"}
            className={`form-item__${item[0]} ${
              requireFields.includes(item[0]) ? "before-form-item" : ""
            }`}
          >
            <InputNumber disabled={disabled} addonAfter={checkAddon(item[0])} />
          </Form.Item>
        );
      case TYPE.upload:
        return (
          <div>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => {
                const fieldValue = getFieldValue(item[0]);
                let fileList = [];
                if (fieldValue) {
                  fileList = [
                    {
                      uid: "-1",
                      name: fieldValue.split("/").pop(),
                      status: "done",
                      url: CONFIG.API_URL.BASE + fieldValue,
                    },
                  ];
                }

                return (
                  <UploadStyled style={{ ...fieldStyles }} hidden={hidden}>
                    <UploadSingle
                      onChange={(val) =>
                        onUpload({ fieldName: item[0], ...val })
                      }
                      fileList={fileList}
                      label={t(`SAMPLE.FORM.${item[0]}`)}
                      disabled={disabled}
                      isShowButton={item[0] !== "barcode" ? true : false}
                    >
                      <Form.Item
                        name={item[0]}
                        hidden={hidden}
                        style={{ display: "none" }}
                        // style={disabled ? { display: "none" } : null}
                      >
                        <Input disabled={disabled} />
                      </Form.Item>
                    </UploadSingle>
                  </UploadStyled>
                );
              }}
            </Form.Item>
          </div>
        );
      case TYPE.dropdown:
        if (
          sectionName === "substanceInformation" ||
          sectionName === "targetInformation"
        ) {
          return (
            <DropdownStyled>
              <Form.Item
                label={t(`SAMPLE.FORM.${item[0][1]}`)}
                name={item[0]}
                labelAlign={"left"}
                className={`form-item__${item[0][1]} ${
                  requireFields.includes(item[0][1]) ? "before-form-item" : ""
                }`}
              >
                <Select
                  showSearch
                  placeholder={t("selectValue")}
                  disabled={disabled}
                  getPopupContainer={(el) => el.parentElement.parentElement}
                >
                  {item[1]?.data?.map((item) => (
                    <Select.Option value={item} key={item}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </DropdownStyled>
          );
        }
        return (
          <DropdownStyled>
            <Form.Item
              label={t(`SAMPLE.FORM.${item[0]}`)}
              name={item[0]}
              labelAlign={"left"}
            >
              <Select
                showSearch
                placeholder={t("selectValue")}
                disabled={disabled}
                getPopupContainer={(el) => el.parentElement.parentElement}
              >
                {item[1]?.data?.map((item) => (
                  <Select.Option value={item} key={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </DropdownStyled>
        );
      case TYPE.date:
        const isSamplingDateInput = item[0] === "samplingDate";
        return (
          <Form.Item
            label={t(`SAMPLE.FORM.${item[0]}`)}
            name={item[0]}
            labelAlign={"left"}
            rules={ruleRequire}
            style={{ ...fieldStyles }}
            hidden={hidden}
            className={`form-item__${item[0]} ${
              requireFields.includes(item[0]) ? "before-form-item" : ""
            }`}
          >
            <DatePicker
              placeholder={""}
              className={"tw-w-full"}
              disabled={disabled}
              format={
                isSamplingDateInput ? "DD/MM/YYYY HH:mm:ss" : "DD/MM/YYYY"
              }
            />
          </Form.Item>
        );
      // return <p>ffff</p>

      default:
        return (
          <Form.Item
            label={t(`SAMPLE.FORM.${item[0]}`)}
            name={item[0]}
            labelAlign={"left"}
            rules={ruleRequire}
            style={{ ...fieldStyles }}
            hidden={true}
            className={`form-item__${item[0]} ${
              requireFields.includes(item[0]) ? "before-form-item" : ""
            }`}
          >
            <Input disabled={disabled} />
          </Form.Item>
        );
    }
  };

  return <Col span={12}>{renderByType(item)}</Col>;
};

const DropdownStyled = styled.div`
  .ant-select-dropdown {
    .ant-select-item[title="mức cao"] {
      background-color: #d9534f;
      color: #fff;
    }
    .ant-select-item[title="mức trung bình"] {
      background-color: #f0ad4e;
      color: #fff;
    }
    .ant-select-item[title="mức thấp"] {
      background-color: #5cb85c;
      color: #fff;
    }
  }
`;
