export const CONFIG = {
  /**
   * development
   * * */
  API_URL: {
    BASE: process.env.REACT_APP_API,
    BASEDOTNET: process.env.REACT_APP_API_DOTNET,
    AUTH: process.env.REACT_APP_API_AUTH,
    DOC: process.env.REACT_APP_API_DOCUMENT,
  },
};
