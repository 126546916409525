import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { styles } from "../style";

export default function PdfTable({
  data,
  currentSubStanceType,
  configObject,
  allowNumericalOrder = true,
  rowCss = {},
}) {
  let tableConfigs = [...(configObject?.tableConfigs || [])];
  allowNumericalOrder &&
    tableConfigs.unshift({
      title: "STT",
      func: (props) => {
        return props.index + 1;
      },
    });
  const widths = configObject?.widths || [];
  const totalColumn = tableConfigs.length;
  const columns = [...Array(totalColumn)].map((_, index) => {
    return {
      title: tableConfigs[index]?.title || "Title" + index,
      css: {
        width: (widths[index] || 10) + "%",
      },
    };
  });

  if (!data) {
    data = [];
  }

  if (data.length > 0) {
    data = data.sort((a, b) =>
      a.sampleIdInternal < b.sampleIdInternal ? -1 : 1
    );
  }

  return (
    <View style={{ position: "relative" }}>
      <View
        style={{
          borderStyle: "solid",
          borderWidth: 0,
          borderTopWidth: 1,
        }}
        fixed={true}
      >
        {/* render header */}
        <View style={styles.tableRow}>
          {columns.map((column, index) => {
            return (
              <View
                key={index}
                style={[
                  index === 0 ? styles.tableColFirst : styles.tableCol,
                  column.css,
                ]}
              >
                <Text style={styles.tableCell}>{column.title}</Text>
              </View>
            );
          })}
        </View>
      </View>

      <View style={styles.table}>
        {/* render body */}
        {data.map((item, index) => {
          const funcProps = {
            value: item,
            index: index,
            array: data,
            substanceType: currentSubStanceType,
            substanceObject: configObject,
          };

          return (
            <View key={index} style={styles.tableRow}>
              {[...Array(totalColumn)].map((_, indexOfColumn) => {
                const custom = tableConfigs[indexOfColumn]?.custom;
                return (
                  <View
                    wrap={false}
                    style={[
                      indexOfColumn === 0
                        ? styles.tableColFirst
                        : styles.tableCol,
                      columns[indexOfColumn].css,
                      rowCss,
                    ]}
                  >
                    {custom ? (
                      custom(funcProps)
                    ) : (
                      <Text style={styles.tableCell}>
                        {tableConfigs[indexOfColumn]?.func(funcProps)}
                      </Text>
                    )}
                  </View>
                );
              })}
            </View>
          );
        })}
      </View>
    </View>
  );
}
