/**
 * Define a DataUtil that supports some functions to work with data processing
 */
import i18next from 'i18next';
import showNotification from '../Components/Nofitication';

export default class FileUtil {
    static downloadFile(blob, filename) {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename || 'download';
        const clickHandler = () => {
            setTimeout(() => {
                URL.revokeObjectURL(url);
                a.removeEventListener('click', clickHandler);
            }, 150);
        };
        a.addEventListener('click', clickHandler, false);
        a.click();
        return a;
    };

    static previewFile(blob, fileType) {
        const url = URL.createObjectURL(blob);
        if (fileType === 'application/pdf') {
            window.open('', '')
                .document.write('<div style="z-index: 900; position: absolute; top: 5px; width: 100%; background-color: #1d39c4; height: 50px"></div>' +
                '<iframe src="' + url + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; padding:0px; margin: 0px; width:100%; height:100%;" allowfullscreen></iframe>')
        } else if (['text/csv', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(fileType)) {
            showNotification("warning", { message: '', description: i18next.t("DOCUMENT.MODAL.REVIEW.ApiNotify.errorType") });
        } else {
            window.open(url, '_blank');
        }
    }

    static printFile(blob, fileType) {
        if (fileType === 'application/pdf') {
            const url = URL.createObjectURL(blob);
            var iframe = document.createElement("iframe");
            document.body.appendChild(iframe);
            iframe.style.display = 'none';
            iframe.src = url;
            iframe.onload = function () {
                setTimeout(() => {
                    iframe.focus();
                    iframe.contentWindow.print();
                }, 1)
            }
        } else {
            showNotification("warning", { message: '', description: i18next.t("DOCUMENT.MODAL.PRINT.Notify.invalidTypeFile") });
        }
    }
}