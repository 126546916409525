import { Text, View } from "@react-pdf/renderer";
import { styles } from "../style";

export default function TextWithCheckBox({ checked, text }) {
  return (
    <View
      style={[
        styles.row,
        {
          alignItems: "center",
          justifyContent: "space-between",
          marginRight: "5px",
        },
      ]}
    >
      {text && <Text style={{ marginRight: "10px" }}>{text}</Text>}

      <View
        style={{
          width: 14,
          height: 14,
          borderStyle: "solid",
          borderWidth: 1,
          bottom: "2px",
        }}
      >
        {checked && (
          <Text
            style={{
              position: "absolute",
              bottom: "-5px",
              right: "2.4px",
            }}
          >
            x
          </Text>
        )}
      </View>
    </View>
  );
}
