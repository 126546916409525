import React from "react";
import Chart from "react-apexcharts";

const ChartStackedColumn = (props) => {
  const months = [];
  props.data.forEach((value) => {
    if (months.includes(value?.monthName)) return;
    months.push(value?.monthName);
  });
  const yArray = [];
  props.data.forEach((value) => {
    const v = value?.substanceType ? value?.substanceType : "Khác";
    if (yArray.includes(v)) return;
    if (!v) return;
    yArray.push(v);
  });
  const data = {
    series: yArray
      .filter((x) => x)
      .map((y) => {
        return {
          name: y,
          data: props.data
            .filter((value) => {
              if (value.substanceType) return value.substanceType === y;
              return "Khác" === y;
            })
            .map((x) => x.countData),
        };
      }),
    options: {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        zoom: {
          enabled: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
        },
      },
      xaxis: {
        categories: months,
      },
      legend: {
        position: "right",
        offsetY: 40,
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: [
          {
            title: {
              formatter: function (val) {
                const importOrigin = props?.data?.[0]?.importOrigin;
                return (importOrigin ? importOrigin + " - " : "") + val;
              },
            },
          },
        ],
      },
    },
  };
  return (
    <Chart
      options={data.options}
      series={data.series}
      type="bar"
      height={350}
    />
  );
};

export default ChartStackedColumn;
