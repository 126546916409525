import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, DatePicker, Form, Input, Modal, Row, Upload } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import FormSection from "src/components/FormSection";
import UploadSingle from "../../components/Upload/UploadSingle";
import styled from "styled-components";
import { formStatus } from "../../redux/client/selector";
import { apiAddClient, apiEditClient } from '../../apis/clients';
import { CONFIG } from "../../configs";
import { saveSampleConfig, getSampleConfigByKey } from '../../apis/saveConfig';
import SelectFormCustom from '../../common/selectFormCustom';

const ClientForm = ({ actionRef, afterSubmit, handleSave }) => {
  const { t } = useTranslation()
  const formRef = useRef()
  const [form] = Form.useForm();
  const [isMounted, setIsMounted] = useState(false);
  const [visible, setVisible] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [editData, setEditData] = useState(null)
  const { submitting } = useSelector(formStatus)
  const ruleRequire = [{ required: true, message: t('VALIDATION.require') }]

  const handleOk = () => {
    if (formRef.current) {
      formRef.current.submit()
    }
  }
  const handleCancel = () => {
    handleClose()
  }
  const handleClose = () => {
    setIsEdit(false)
    setEditData(null)
    setVisible(false)
    form.resetFields()
  }
  const handleOpen = () => {
    setVisible(true)
  }

  const setFieldsValue = (values) => {
    setIsEdit(true)
    setEditData(values)
    form.setFieldsValue({
      "address": values.address,
      "city": values.city,
      "clientType": values.clientType,
      "email": values.email,
      // "entryDate": values.entryDate,
      "externalId": values.externalId,
      "facilityName": values.facilityName,
      "firstName": values.firstName,
      "identificationNumber": values.identificationNumber,
      "imageCaption": values.imageCaption,
      "imageUrl": values.imageUrl,
      "lastName": values.lastName,
      "phoneNo": values.phoneNo,
    })
  }

  const onUpload = (val) => {
    const { fieldName, path } = val;
    form.setFieldsValue({
      [fieldName]: path,
    });
  };

  const onFinish = async (data) => {
    if (isEdit) {
      await apiEditClient(editData.id, {
        "address": data.address,
        "city": data.city,
        "clientType": data.clientType,
        "email": data.email,
        "entryDate": data.entryDate,
        "externalId": data.externalId,
        "facilityName": data.facilityName,
        "firstName": data.firstName,
        "identificationNumber": data.identificationNumber,
        "imageCaption": data.imageCaption,
        "imageUrl": data.imageUrl,
        "lastName": data.lastName,
        "phoneNo": data.phoneNo,
        "samplesId": [

        ]
      })
    } else {
      await apiAddClient({
        "address": data.address,
        "city": data.city,
        "clientType": data.clientType,
        "email": data.email,
        "entryDate": data.entryDate,
        "externalId": data.externalId,
        "facilityName": data.facilityName,
        "firstName": data.firstName,
        "identificationNumber": data.identificationNumber,
        "imageCaption": data.imageCaption,
        "imageUrl": data.imageUrl,
        "lastName": data.lastName,
        "phoneNo": data.phoneNo,
        "samplesId": [

        ]
      })
    }
    handleClose();
    handleSave && handleSave();
  }

  useEffect(() => {
    if (!isMounted) return
    if (!submitting) {
      setVisible(false)
      form.resetFields()
      if (afterSubmit) {
        afterSubmit()
      }
    }
    // eslint-disable-next-line
  }, [submitting])

  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true)
    }
    const userAction = {
      close: handleClose,
      open: handleOpen,
      setFieldsValue: setFieldsValue
    }
    if (actionRef && typeof actionRef === 'function') {
      actionRef(userAction);
    }
    if (actionRef && typeof actionRef !== 'function') {
      actionRef.current = userAction;
    }
    return () => {
      setIsMounted(false)
    }
    // eslint-disable-next-line
  }, [])

  const [dataClient, setDataClient] = useState([]);
  useEffect(() => {
    getList()
  }, [])

  const getList = async () => {
    const { data } = await getSampleConfigByKey("CUSTOMER_CLASSIFICATION")
    setDataClient((data?.values || []).map(x => { return { name: x, id: x } }))
  }

  const handleOkTestCode = async (name) => {
    await saveSampleConfig({
      value: name,
      key: "CUSTOMER_CLASSIFICATION"
    })
    await getList()
  }

  return (
    <div>
      <Modal
        centered={true}
        title={t('Add Client')}
        visible={visible}
        onOk={handleOk}
        confirmLoading={submitting}
        onCancel={handleCancel}
        cancelButtonProps={{ style: { display: 'none' } }}
        okText={t('BUTTON.save')}
        okButtonProps={{ htmlType: 'submit' }}
        width={1000}
        forceRender
      >
        <Form
          ref={formRef}
          form={form}
          name="basic"
          labelCol={{ span: 11 }}
          wrapperCol={{ span: 13 }}
          // initialValues={initialValueForm}
          onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
        >

          {/** =========== SECTION GENERAL ========== **/}
          <FormSection header={t('CLIENT.General_Information')}>
            <Row gutter={[32, 8]}>
              <Col span={12}>
                <Form.Item label={t('CLIENT.Facility_Name')} name="facilityName" labelAlign={'left'} >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={t('CLIENT.Client_ID')} name="externalId" labelAlign={'left'}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={t('CLIENT.Address')} name="address" labelAlign={'left'}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue }) => {
                    const formItemValue = getFieldValue("clientType");
                    let defaultOptions = [];
                    if (formItemValue) {
                      defaultOptions = [{ value: getFieldValue("clientType"), text: getFieldValue("clientType") }];
                    }

                    return (
                      <Form.Item
                        label={t('CLIENT.city')}
                        name={"clientType"}
                        labelAlign={"left"}
                        rules={ruleRequire}
                      >
                        <SelectFormCustom
                          label='Test code'
                          options={dataClient.map(x => { return { ...x } })}
                          value={""}
                          // onChange={(e) => handleChange(e, "testCodeId")}
                          handleOk={handleOkTestCode}
                        />
                      </Form.Item>
                    )
                  }}
                </Form.Item>
              </Col>
              {/* <Col span={12}>
                <Form.Item label={t('CLIENT.Entry_Date')} name="entryDate" labelAlign={'left'} >
                  <DatePicker placeholder={''} onChange={onChange} className={'tw-w-full'} />
                </Form.Item>
              </Col> */}
              {/* <Col span={12}>
                <Form.Item label={t('CLIENT.Last_Name')} name="lastName" labelAlign={'left'} rules={ruleRequire}>
                  <Input />
                </Form.Item>
              </Col> */}
              {/* <Col span={12}>
                <Form.Item label={t('CLIENT.Lic')} name="lic" labelAlign={'left'} >
                  <Input />
                </Form.Item>
              </Col> */}
              {/* <Col span={12}>
                <Form.Item label={t('CLIENT.Facility_ID_External')} name="facilityIDExternal" labelAlign={'left'} >
                  <Input />
                </Form.Item>
              </Col> */}
              {/* <Col span={12}>
                <Form.Item label={t('CLIENT.Account')} name="account" labelAlign={'left'} >
                  <Input />
                </Form.Item>
              </Col> */}
            </Row>
          </FormSection>
          {/** =========== SECTION GENERAL ========== **/}


          {/** =========== SECTION CONTACT ========== **/}
          <FormSection header={t('CLIENT.Contact_Information')}>
            <Row gutter={[32, 8]}>
              <Col span={12}>
                <Form.Item label={t('CLIENT.First_Name')} name="firstName" labelAlign={'left'} rules={ruleRequire}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={t('CLIENT.Email')} name="email" labelAlign={'left'} rules={ruleRequire}>
                  <Input />
                </Form.Item>
              </Col>
              {/* <Col span={12}>
                <Form.Item label={t('CLIENT.Street')} name="street" labelAlign={'left'} rules={ruleRequire}>
                  <Input />
                </Form.Item>
              </Col> */}
              <Col span={12}>
                <Form.Item label={t('CLIENT.Phone_No')} name="phoneNo" labelAlign={'left'}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={t('CLIENT.Identification_Number')} name="identificationNumber" labelAlign={'left'}>
                  <Input />
                </Form.Item>
              </Col>
              {/* <Col span={12}>
                <Form.Item label={t('CLIENT.Zip_Code')} name="zipCode" labelAlign={'left'}>
                  <Input />
                </Form.Item>
              </Col> */}
              {/* <Col span={12}>
                <Form.Item label={t('CLIENT.Bill_To')} name="billTo" labelAlign={'left'}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={t('CLIENT.Ship_To')} name="shipTo" labelAlign={'left'}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={t('CLIENT.State')} name="state" labelAlign={'left'}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={t('CLIENT.Alternate_Email')} name="alternateEmail" labelAlign={'left'}>
                  <Input />
                </Form.Item>
              </Col> */}
              {/* <Col span={12}>
                <Form.Item label={t('CLIENT.city')} name="city" labelAlign={'left'}>
                  <Input />
                </Form.Item>
              </Col> */}
            </Row>
          </FormSection>
          {/** =========== SECTION CONTACT ========== **/}


          {/** =========== SECTION ASSOCIATED INFORMATION ========== **/}
          {/* <FormSection header={t('CLIENT.Associated_Information')}>
            <Row gutter={[32, 8]}>
              <Col span={12}>
                <Form.Item label={t('CLIENT.Associated_Samples')} name="associatedSamples" labelAlign={'left'}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </FormSection> */}
          {/** =========== SECTION ASSOCIATED INFORMATION ========== **/}


          {/** =========== SECTION CLIENT IMAGE ========== **/}
          <FormSection header={t('CLIENT.Client_Image')}>
            <Row gutter={[32, 8]}>
              <Col span={12}>
                <div>
                  <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue }) => {
                      const fieldValue = getFieldValue("imageUrl");
                      let fileList = [];
                      if (fieldValue) {
                        fileList = [
                          {
                            uid: "-1",
                            name: fieldValue.split("/").pop(),
                            status: "done",
                            url: CONFIG.API_URL.BASE + fieldValue,
                          },
                        ];
                      }

                      return (
                        <UploadStyled>
                          <UploadSingle
                            onChange={(val) =>
                              onUpload({ fieldName: "imageUrl", ...val })
                            }
                            fileList={fileList}
                            label={"Ảnh"}
                            isShowButton={true}
                          >
                            <Form.Item
                              name={"imageUrl"}
                              style={{ display: "none" }}
                            // style={disabled ? { display: "none" } : null}
                            >
                              <Input />
                            </Form.Item>
                          </UploadSingle>
                        </UploadStyled>
                      );
                    }}
                  </Form.Item>
                </div>
              </Col>
              <Col span={12}>
                <Form.Item label={t('CLIENT.Caption')} name="imageCaption" labelAlign={'left'}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </FormSection>
          {/** =========== SECTION CLIENT IMAGE ========== **/}

        </Form>
      </Modal>
    </div>
  );
};

ClientForm.propTypes = {
  actionRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  afterSubmit: PropTypes.func
};

export default ClientForm;

const UploadStyled = styled.div`
  .ant-upload-picture-card-wrapper {
    width: 100%;
    height: 200px;
    overflow: hidden;
  }
  .ant-upload-select-picture-card {
    width: 100%;
    height: 200px;
  }
  .ant-upload-list-picture-card-container {
    width: 100%;
    height: 200px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
`
