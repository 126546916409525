import React, { useState } from "react";
import { Button, Form, Row, Col, Checkbox } from "antd";
import styled from "styled-components";
// import picLogin from 'src/assets/images/logo512.png';
import ImgBgLogin from "src/assets/images/bg_login.jpg";
import { useDispatch, useSelector } from "react-redux";
import * as loginAction from "src/redux/login/slice";
import { loginStatus } from "src/redux/login/selector";
import PmInput from "src/components/input/PmInput";
import PmInputPassword from "src/components/input/PmInputPassword";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { theme } from "src/constants/color.constant";
import { Radio } from "antd";
import { useTranslation } from "react-i18next";
import { detectLanguage } from "src/utils/utils";
import { loginApi } from "../../apis/login";
import { REFRESH_TOKEN, TOKEN_NAME } from "../../constants";

const LoginWrapper = styled.div`
  background-image: url("${ImgBgLogin}");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  .login-card-container {
    @media (min-width: 1200px) {
      min-height: 80vh;
    }
  }
  .img {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 0;
  }
`;

const CheckboxGreen = styled.div`
  .ant-checkbox-checked::after {
  }
  .ant-checkbox-inner {
    background-color: ${theme.colors.green["300"]};
    width: 19px;
    height: 19px;
  }
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: ${theme.colors.green["300"]};
      border-color: ${theme.colors.green["300"]};
    }
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner {
    border-color: ${theme.colors.green["300"]};
  }
`;

const BtnStyled = styled.div`
  .ant-btn {
    width: 100%;
  }
`;

/**
 * Login Form
 */
const LoginForm = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [langue, setLangue] = useState(detectLanguage());
  const { loading } = useSelector(loginStatus);
  // const loginError = useSelector(selectMessageErr);
  const [loginErrors, setLoginErrors] = useState('')

  const onFinish = async (data) => {
    console.log(`data`, data)
    return
    // dispatch(loginAction.doLogin(data));
    const res = await loginApi(data)
    if (res.data.status === 1) {
      const data = res.data.data
      const token = data.access_token
      const IdUser = data.GeneralInfomation.IdUser
      localStorage.setItem('IdUser', IdUser);
      localStorage.setItem('Username', data.LoginInfomation.Username);
      dispatch(loginAction.updateToken(token))
      localStorage.setItem(REFRESH_TOKEN, data.refresh_token)
    } else {
      setLoginErrors(t('LOGIN.errorLogin'))
    }
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  const onChange = (e) => {
    setLangue(e.target.value);
    i18n.changeLanguage(e.target.value).then();
    // if (e.target.value === 'en') {
    //   message.success('Switch Language English Success');
    // } else {
    //   message.success('Switch Language Vietnamese Success');
    // }
  };

  return (
    <LoginWrapper className="tw-h-full tw-px-5">
      <Row className="login-card-row tw-h-full" justify="start" align="middle">
        <Col
          flex={"450px"}
          className="login-card-container tw-flex tw-flex-col tw-justify-between tw-bg-white tw-rounded-3xl tw-pt-4 tw-p-12 padding-0"
        >
          <div className="login-card-content">
            {/* <div className="tw-text-right">
              <Radio.Group size="middle" onChange={onChange} value={langue}>
                <Radio value="vi" style={{ paddingRight: 20 }}>
                  <img
                    className="img"
                    src="/images/vn1.png"
                    align="top"
                    alt=""
                  />
                </Radio>
                <Radio value="en">
                  <img
                    className="img"
                    src="/images/my.png"
                    align="top"
                    alt=""
                  />
                </Radio>
              </Radio.Group>
            </div> */}
            <div className="login-card__group tw-pt-1.5">
              <h1 className="tw-font-PlayfairDisplay tw-font-bold tw-text-green-950 tw-text-4xl tw-pt-1.5 tw-text-center tw-mb-11">
                {langue === "vi" ? "Đăng ký" : "Register"}
              </h1>
              <div className={"tw-text-red-600 tw-mb-2"}>{loginErrors}</div>
              <Form
                name="loginForm"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item
                  name="name"
                  rules={[
                    { required: true, message: "Please input your username!" },
                  ]}
                  className={"tw-mb-3"}
                >
                  <PmInput
                    placeholder={"Name"}
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  name="username"
                  rules={[
                    { required: true, message: "Please input your username!" },
                  ]}
                  className={"tw-mb-3"}
                >
                  <PmInput
                    placeholder={t("LOGIN.placeholderInputUsername")}
                    size="large"
                  />
                </Form.Item>

                <Form.Item
                  name="password"
                  rules={[
                    { required: true, message: "Please input your password!" },
                  ]}
                  className={"tw-mb-3"}
                >
                  <PmInputPassword
                    placeholder={t("LOGIN.placeholderInputPassword")}
                    size="large"
                    iconRender={(visible) =>
                      visible ? (
                        <EyeOutlined
                          style={{ fontSize: 24, color: "#032D23" }}
                        />
                      ) : (
                        <EyeInvisibleOutlined
                          style={{ fontSize: 24, color: "#032D23" }}
                        />
                      )
                    }
                  />
                </Form.Item>
                <Form.Item
                  name="confirmPassword"
                  rules={[
                    { required: true, message: "Please input your password!" },
                  ]}
                  className={"tw-mb-3"}
                >
                  <PmInputPassword
                    placeholder={"Xác nhận mật khẩu"}
                    size="large"
                    iconRender={(visible) =>
                      visible ? (
                        <EyeOutlined
                          style={{ fontSize: 24, color: "#032D23" }}
                        />
                      ) : (
                        <EyeInvisibleOutlined
                          style={{ fontSize: 24, color: "#032D23" }}
                        />
                      )
                    }
                  />
                </Form.Item>
                <Form.Item
                  name="email"
                  rules={[
                    { required: true, message: "Please input your username!" },
                  ]}
                  className={"tw-mb-3"}
                >
                  <PmInput
                    placeholder={"Email"}
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  name="address"
                  className={"tw-mb-3"}
                >
                  <PmInput
                    placeholder={"Địa chỉ"}
                    size="large"
                  />
                </Form.Item>

                <Form.Item
                  name="phone"
                  className={"tw-mb-3"}
                >
                  <PmInput
                    placeholder={"Số điện thoại"}
                    size="large"
                  />
                </Form.Item>

                <BtnStyled className="btn-login tw-mb-8">
                  <Button
                    loading={loading}
                    type="primary"
                    size={"large"}
                    className={
                      "tw-font-Inter tw-font-normal tw-text-white tw-text-xl tw-rounded-md tw-bg-green-950 tw-border-green-950 tw-h-16"
                    }
                    htmlType="submit"
                  >
                    {langue === "vi" ? "Đăng ký" : "Register"}
                  </Button>
                </BtnStyled>

                {/* <div
                  className={
                    "tw-font-Inter tw-font-normal tw-text-green-950 tw-text-xl"
                  }
                >
                  <Link to={"/login"} className={"tw-text-green-300"}>
                    {langue === "vi" ? "Quay lại" : "Back"}
                  </Link>
                </div> */}
              </Form>
            </div>
          </div>
          {/* <div
            className={
              "tw-font-Inner tw-font-normal tw-text-green-950 tw-text-base"
            }
          >
            <p className={"tw-mb-0"}>
              Copyright © 2021 <span>NTS</span> All rights reserved.
            </p>
            <p>Terms & Conditions</p>
          </div> */}
        </Col>
      </Row>
    </LoginWrapper>
  );
};

export default LoginForm;
