import { View, Text, PDFViewer, Page, Document } from "@react-pdf/renderer";
import { styles } from "../Common/style";
import PdfTable from "../Common/Components/pdfTable";
import { fName, get } from "../Common/reportConfig";

export default function SoNhapMauGui(props) {
  const { data, currentSubStanceType, sampleTemplate, dicReports } = props;
  const code = CONFIG_OBJECT.code || "";

  data &&
    data.forEach((element) => {
      element.soBienBan = dicReports[element.sampleId];
    });

  return (
    <>
      <PDFViewer style={{ width: "100%", height: "calc(100% - 40px)" }}>
        <Document>
          <Page size="A4" style={styles.pageBook} orientation="landscape">
            <View>
              <PdfTable
                allowNumericalOrder={false}
                data={data}
                currentSubStanceType={currentSubStanceType}
                configObject={CONFIG_OBJECT}
              />
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </>
  );
}

const CONFIG_OBJECT = {
  value: "Sổ mẫu lưu",
  code: "BM.06/QT.07/KNĐL/2023(01)",
  reportName: "SỔ LƯU MẪU",
  widths: [5, 6, 10, 8, 10, 6, 8, 9, 10, 8, 8, 6, 6],
  tableConfigs: [
    {
      title: "Ngày tiếp nhận mẫu",
      func: (props) => {
        return get.byDate(props, "receivedDate");
      },
    },
    {
      title: "Số ĐKKN",
      func: (props) => {
        return get.byName(props, "registrationNumber");
      },
    },
    {
      title: "Tên mẫu",
      func: (props) => {
        return get.byName(props, "sampleName");
      },
    },
    {
      title: "Hạn dùng",
      func: (props) => {
        return get.byDate(props, "expirationDate");
      },
    },
    {
      title: "Số PYCKN/Hợp đồng thử nghiệm/Công văn gửi mẫu",
      func: (props) => {
        return get.byName(props, "soBienBan");
      },
    },
    {
      title: "Đơn vị tính",
      func: (props) => {
        return get.byDate(props, "packedSize");
      },
    },
    {
      title: "Số lượng nhận",
      func: (props) => {
        return get.byNames(props, ["totalSample"]);
      },
    },
    {
      title: "Số lượng lưu",
      func: (props) => {
        return get.byNames(props, ["numberSamplesSaved"]);
      },
    },
    {
      title: "Số lượng PTN",
      func: (props) => {
        return ""; // get.byNames(props, [fName.NhanXet]);
      },
    },
    {
      title: "Số lượng DPTNL2",
      func: (props) => {
        return ""; // get.byNames(props, [fName.NhanXet]);
      },
    },
    {
      title: "Ngày PTN nhận mẫu, ký nhận",
      func: (props) => {
        return get.byDate(props, ["dateExportSavedSamples"]);
      },
    },
    {
      title: "Thời gian trả HSKN",
      func: (props) => {
        return get.byDate(props, ["appointmentDate"]);
      },
    },
    {
      title: "Ngày PTN trả HSKN, ký trả",
      func: (props) => {
        return ""; // get.byNames(props, [fName.NhanXet]);
      },
    },
  ],
};
