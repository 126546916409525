import { Text, View } from "@react-pdf/renderer";
import { styles } from "./style";

export const get = {
  substanceInformations: (props) => {
    return props?.value?.substanceInformation || [];
  },
  substanceInformation: (props) => {
    return get.substanceInformations(props)[0];
  },
  byTarget: (props, name) => {
    return (props?.value?.targetInformation || {})[name] || "";
  },
  byName: (props, name) => {
    const sStanceInfo = get.substanceInformation(props);
    return (sStanceInfo || {})[name] || (props?.value || {})[name] || "";
  },
  byDate: (props, name) => {
    let result = get.byName(props, name);

    return getShortDate(result) || result;
  },
  byNames: (props, names, prefix = " - ") => {
    return names
      .map((name) => get.byName(props, name))
      .filter((x) => x)
      .join(prefix);
  },
  byTableColName: (props, prefix = " - ") => {
    const arr = [
      get.byName(props, "dosageForm"),
      get.byName(props, "sampleName"),
    ];
    get.substanceInformations(props).forEach((item) => {
      arr.push(item.substanceName);
      arr.push(item.substanceContent);
      arr.push(item.substanceUnit);
    });
    arr.push(get.byName(props, "registerNumber"));
    arr.push(get.byName(props, "sampleIdExternal"));
    return arr.filter((x) => x).join(prefix);
  },
  byFullSampleNameAndCustoms: (props, lastItems, prefix = " - ") => {
    const arr = [get.byName(props, "sampleName")];
    get.substanceInformations(props).forEach((item) => {
      arr.push(item.substanceName);
      arr.push(item.substanceContent);
      arr.push(item.substanceUnit);
    });
    lastItems.forEach((lastItem) => {
      arr.push(get.byName(props, lastItem));
    });
    return arr.filter((x) => x).join(prefix);
  },
};

const getShortDate = (d) => {
  const date = new Date(d);
  if (date instanceof Date && !isNaN(date)) {
    return `${("0" + date.getDate()).slice(-2)}/${(
      "0" +
      (date.getMonth() + 1)
    ).slice(-2)}/${date.getFullYear().toString().substr(-2)}`;
  }

  return undefined;
};

export const fName = {
  TenHoatChat: "substanceName",
  TenMyPham: "sampleName",
  TenDuocLieu: "substanceName",
  TenThuoc: "substanceName",
  SoDangKy: "registerNumber",
  LoSanXuat: "chronicleBatchDate",
  NgaySX: "productionDate",
  HanDung: "expirationDate",
  DVDongGoiNhoNhat: "unit",
  SLLay: "currentQuantity",
  DonViSlLay: "packedSize",
  TenNhaSX: "producerName",
  DiaChi: "producerAddress",
  NhanXet: "status",
  HamLuong: "substanceContent",
  TenKhoaHoc: "scienceName",
  SoTieuChuan: "NULL",
  TenToChucChiuTrachNhiem: "organizationName",
  TenDiaChiChiuTrachNhiem: "organizationAddress",
  KhoiLuongMauDcLay: "currentQuantity",
  TenNhaNhapKhau: "importFacility",
  PhuongPhap: "testMethod",
  KetQuaKiemNghiem: "documentName",
  ChiTieuKiemNghiem: "testType",
  ChiTieuPhanTich: "testType",
  TenMau: "sampleName",
  TinhTrangKhiNhanMau: "status",
  DonVi: "substanceUnit",
  MaPhanTich: "sampleIdExternal",
  NguoiNhanMau: "custodianName",
  NgayTraKetQua: "appointmentDate",
  LuongMauConDu: "totalSample",
  YeuCauKN: "samplerRequired",
  GhiChu: "remark",
};

export const REPORT_YEN_BAI = {
  bienBanLayMau: {
    value: "Biên bản lấy mẫu",
    reportName: "Lấy mẫu thuốc/mỹ phẩm để xác định chất lượng",
    code: "KNYB/BM.7.3.01.01",
    tableConfigs: [
      {
        title: "Tên mẫu, nồng độ, hàm lượng, số đăng ký/số công bố",
        func: get.byTableColName,
      },
      {
        title: "Lô sản xuất, Ngày SX, hạn dùng",
        func: (props) => {
          return get.byNames(props, [
            fName.LoSanXuat,
            fName.NgaySX,
            fName.HanDung,
          ]);
        },
        custom: (props) => {
          return (
            <View>
              <Text style={styles.tableCell}>
                {get.byName(props, fName.LoSanXuat)}
              </Text>
              <Text style={styles.tableCell}>
                {get.byName(props, fName.NgaySX)}
              </Text>
              <Text style={styles.tableCell}>
                {get.byName(props, fName.HanDung)}
              </Text>
            </View>
          );
        },
      },
      {
        title: "Đơn vị đóng gói nhỏ nhất",
        func: (props) => {
          return get.byNames(props, [fName.DVDongGoiNhoNhat]);
        },
      },
      {
        title: "Số lượng mẫu lấy",
        func: (props) => {
          return get.byNames(props, [fName.SLLay, fName.DonViSlLay], " ");
        },
      },
      {
        title: "Tên nhà sản xuất và địa chỉ",
        func: (props) => {
          return get.byNames(props, [fName.TenNhaSX, fName.DiaChi]);
        },
      },
      {
        title: "Tên nhà nhập khẩu(nếu là sản phẩm NK) nhà phân phối",
        func: (props) => {
          return get.byNames(props, [
            fName.TenToChucChiuTrachNhiem,
            fName.TenDiaChiChiuTrachNhiem,
          ]);
        },
      },
      {
        title:
          "Nhận xét tình trạng mẫu trước khi lấy; điều kiện bảo quản(nếu có)",
        func: (props) => {
          return get.byNames(props, [fName.NhanXet]);
        },
      },
    ],
    widths: [5, 20, 10, 10, 5, 20, 15, 15],
  },

  bienBanBanGiaoMau: {
    value: "Biên bản bàn giao mẫu",
    reportName: "BIÊN BẢN BÀN GIAO MẪU",
    code: "",
    tableConfigs: [
      {
        title: "Tên mẫu",
        func: (props) => {
          return get.byNames(props, [fName.TenMau]);
        },
      },
      {
        title: "Số ĐKKN",
        func: (props) => {
          return get.byNames(props, [fName.SoDangKy]);
        },
      },
      {
        title: "Đơn vị tính",
        func: (props) => {
          return get.byNames(props, [fName.DonVi]);
        },
      },
      {
        title: "Số lượng mẫu",
        func: (props) => {
          return get.byNames(props, [fName.SLLay]);
        },
      },
      {
        title: "Yêu cầu kiểm nghiệm",
        func: (props) => {
          return get.byNames(props, [fName.YeuCauKN]);
        },
      },
      {
        title: "Tiêu chuẩn chất lượng",
        func: (props) => {
          //return get.byNames(props, [fName.TenDiaChiChiuTrachNhiem]);
          //TODO: SSOI MISS
          return "";
        },
      },
      {
        title: "Ngày trả HSKN",
        func: (props) => {
          return get.byNames(props, [fName.NgayTraKetQua]);
        },
      },
      {
        title: "Ghi chú",
        func: (props) => {
          return get.byNames(props, [fName.GhiChu]);
        },
      },
      {
        title: "Tiêu chuẩn",
        func: (props) => {
          //TODO: SSOI MISS
          //return get.byNames(props, [fName.NhanXet]);
          return "";
        },
      },
    ],
    widths: [5, 20, 10, 10, 5, 10, 10, 10, 10, 10],
  },

  phieuPhanTich: {
    value: "Phiếu phân tích",
    reportName: "Phiếu phân tích",
    code: "",
    tableConfigs: [
      {
        title: "Chỉ tiêu chất lượng",
        func: (props) => {
          return get.byTarget(props, "targetName");
        },
      },
      {
        title: "Yêu cầu chất lượng",
        func: (props) => {
          return get.byTarget(props, "targetMethod");
        },
      },
      {
        title: "Kết quả",
        func: (props) => {
          return "";
        },
      },
    ],
    widths: [10, 30, 30, 30],
  },
};
